import React, { Fragment, useEffect, useState } from "react";

import SliderHeader from "../../../../components/ui/sliderHeader/SliderHeader";
import DesignButton from "../../../../components/ui/button/DesignButton";

import { Box, Typography } from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import CarouselCardDetails from "./CarouselCardDetails";

import "../styles/slider.css";

const CarouselModal = ({ allData }) => {
  const [selectedCardOptions, setSelectedCardOptions] = useState(null);

  useEffect(() => {
    setSelectedCardOptions(null);
  }, [allData.index, allData.data]);

  const [optionIndx, setOptionIndx] = useState();

  const handleAddNewCard = () => {
    allData.data[allData.index].cardCounter++;
    allData.addChildCarousel(allData.index);
  };

  const handleCardClick = (optionIndex) => {
    setSelectedCardOptions(allData.data[allData.index].options[optionIndex]);
    setOptionIndx(optionIndex);
  };

  const handleSelectedCardOptions = (cardOption) => {
    setSelectedCardOptions(cardOption);
  };

  return (
    <Box
      className={"slider"}
      style={{ right: allData.isOpen ? "0" : "-350px" }}
    >
      {selectedCardOptions ? (
        <CarouselCardDetails
          allData={allData}
          selectedCardOptions={selectedCardOptions}
          optionIndx={optionIndx}
          handleSelectedCardOptions={handleSelectedCardOptions}
          handleCardClick={handleCardClick}
        />
      ) : (
        <Fragment>
          <SliderHeader
            closeData={allData}
            headerMessage={
              "Carousels are a set of cards which display items with CTAs to each card"
            }
            nodeType={allData.nodeType}
          ></SliderHeader>
          <Box
            className="add-btn-list"
            style={{
              marginTop: "30px",
              borderBottom: "3px solid #7d7d7d",
              marginBottom: "120px",
            }}
          >
            {allData?.data[allData.index]?.chidlrenDataIndex?.map(
              (item, index) => {
                const nodeIndex = allData.data.findIndex(
                  (node) => node.id === item
                );
                return (
                  <Box className="buttons-info">
                    <Box className="all-button-data">
                      <Box
                        className="name-area"
                        style={{
                          borderRadius: "5px",
                          cursor: "pointer",
                          backgroundColor: "#f6fafa",
                          height: "65px",
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                        onClick={() => handleCardClick(index)}
                      >
                        <Box
                          style={{
                            height: "100%",
                            width: "65px",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          {allData.data[allData.index].options[index].img ===
                          "" ? (
                            <Box
                              style={{
                                height: "100%",
                                width: "65px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ImageOutlinedIcon />
                            </Box>
                          ) : (
                            <img
                              style={{
                                height: "100%",
                                width: "65px",
                                borderTopLeftRadius: "5px",
                                borderBottomLeftRadius: "5px",
                              }}
                              src={
                                allData.data[allData.index].options[index].img
                              }
                              alt=""
                            />
                          )}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "15px",
                              color: "#101010",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "182px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {allData.data[nodeIndex].data.label}
                          </Typography>
                          <ChevronRightOutlinedIcon />
                        </Box>
                      </Box>

                      {allData.data[allData.index].chidlrenDataIndex.length >
                      1 ? (
                        <Box className="delet-btn">
                          <DeleteOutlinedIcon
                            className="delete-outlined-icon"
                            onClick={() => {
                              const buttonsToRemove =
                                allData.data[allData.index].options[index]
                                  .buttons;

                              allData.data[
                                allData.index
                              ].chidlrenDataIndex.splice(index, 1);
                              allData.reRender();

                              allData.data.splice(nodeIndex, 1);
                              allData.reRender();

                              const idToRemove =
                                allData.data[allData.index].childrenPathIndex[
                                  index
                                ];
                              const indexToRemove = allData.pathData.findIndex(
                                (pathItem) => pathItem.id === idToRemove
                              );
                              allData.pathData.splice(indexToRemove, 1);
                              allData.reRender();

                              allData.data[
                                allData.index
                              ].childrenPathIndex.splice(index, 1);
                              allData.reRender();

                              allData.removeButtonsCarousel(buttonsToRemove);

                              // Re-render or update your UI as needed
                              allData.reRender();

                              allData.data[allData.index].options.splice(
                                index,
                                1
                              );
                              allData.reRender();
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                );
              }
            )}
            <Typography style={{ fontSize: "11px", color: "#858585" }}>
              {" "}
              Ideal number is between 3-7
            </Typography>
            {allData?.data[allData.index]?.chidlrenDataIndex.length < 10 && (
              <DesignButton
                func={() => handleAddNewCard()}
                text="Card"
              ></DesignButton>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default CarouselModal;
