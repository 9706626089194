import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";

import Niyat_logo from "../../../assets/images/logo.jpg";

import {
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  TextField,
  Button,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import axios from "axios";

import { baseURL } from "../../../axiosApi/axiosApi.js";

import CryptoJS from "crypto-js";

import Styles from "./Login.module.css";

const Login = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };
  const [email, setEmail] = useState();
  const [password, setpassword] = useState();
  const [errorDiv, setErrorDiv] = useState(false);
  const navigate = useNavigate();

  const navigatehandler = async (e) => {
    const args = { UserName: email, password: password };
    try {
      const { data } = await axios.get(
        `${baseURL}/chatbot/KBot/SignIn?userdata=${JSON.stringify(args)}`
      );
      const accountDATA = (data[0] && JSON.parse(data[0].jsonData)) || [];
      if (accountDATA.Get) {
        setErrorDiv(true);
      } else if (accountDATA[0].AccountNumber) {
        navigate(`/`);
        localStorage.setItem("AC", accountDATA[0].AccountNumber);
      }
    } catch (e) {
      console.log("ERROR");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = async (e, type) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    setValue(type, newEmail);
    await trigger(type);
  };

  const handlePassChange = async (e, type) => {
    const newPass = e.target.value;
    setpassword(newPass);

    setValue(type, newPass);
    await trigger(type);
  };

  return (
    <Fragment>
      <Box className={Styles.loginCont}>
        <Box className={Styles.formCont}>
          <img src={Niyat_logo} alt="niyat-logo"></img>
          <Box className={Styles.words}>
            <Typography className={Styles.title}>Welcome back</Typography>
            <Typography className={Styles.someTools}>
              Sign in and build some cool bots
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(navigatehandler)}>
            <FormControl isInvalid={errors.email}>
              <Typography >Email <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Wrong Email Pattern",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "It Mustn't contains only spaces",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => {
                      handleEmailChange(e, "email");
                    }}
                    type="email"
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.email && errors.email.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <Typography>Password <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "Cannot be only spaces",
                  },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    onChange={(e) => {
                      handlePassChange(e, "password");
                    }}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.password && errors.password.message}
              </Typography>
            </FormControl>

            <Box className={Styles.forgetPassCont}>
              <Link to={"/checkEmail"}>Forget Passowrd </Link>
            </Box>

            <Button className={Styles.signIn} variant="contained" type="submit">
              Sign In
            </Button>

            <Typography>
              Don't have an account? <Link to={"/register"}>Sign up</Link>
            </Typography>
          </form>
          {errorDiv && (
            <Box style={{ color: "red" }}> Email or Password is wrong</Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default Login;
