import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import Reactquill from "../../../components/ui/reactquill/Reactquill";
import { Box, Typography } from "@mui/material";

function CollectInput({ allData }) {
  const [editorContent, setEditorContent] = useState([""]);

  useEffect(() => {
    const updatedEditorContent = [...editorContent];
    updatedEditorContent[0] = allData.data[allData.index].data.description;
    setEditorContent(updatedEditorContent);
  }, [allData.index, allData.data]);

  return (
    <Box
      className={"slider"}
      style={{ right: allData.isOpen ? "0" : "-350px" }}
    >
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Collect input asks a question to a user which requires an answer"
        }
        toastHeader="Collect Input"
        nodeType={allData.nodeType}
      ></SliderHeader>

      {editorContent.map((content, index2) => (
        <Box className="slider-comp">
          <Box className="disc">
            <Typography className="message-description">
              Shows this question
            </Typography>
          </Box>
          <Reactquill
            ediotrs={editorContent}
            cont={content}
            index={index2}
            Data={allData}
            placehold="What is your…"
          ></Reactquill>
        </Box>
      ))}
    </Box>
  );
}

export default CollectInput;
