import React, { useState } from "react";
import { Box } from "@mui/material";
import NavBar from "../features/howToUse/NavBar";
import SideBar from "../features/howToUse/SideBar";
import { Outlet } from "react-router-dom";

const DocLayOut = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
    >
      <NavBar onToggleSidebar={toggleSidebar} />
      <SideBar open={sidebarOpen} />
      <Outlet />
    </Box>
  );
};

export default DocLayOut;
