import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IOSSwitch from "../../../../../components/ui/switch/IosSwitch";
import DeleteButton from "../../../../../components/ui/button/DeleteButton";
import DesignButton from "../../../../../components/ui/button/DesignButton";

const AddNewField = ({
  close,
  open,
  allData,
  setAllInputsTypes,
  allInputsTypes,
}) => {
  const [newFieldName, setNewFieldName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false);
  const [newFieldType, setNewFieldType] = useState("text");
  const [optionalField, setOptionalField] = useState(false);

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setNewFieldName(nameValue);

    // Check if the name is valid (not empty or containing only symbols)
    const isValid = nameValue.trim() !== "" && /[a-zA-Z]/.test(nameValue);
    setIsNameValid(isValid);
  };

  const toggleInputFields = () => {
    setShowInputFields(!showInputFields);
  };

  const addInputField = () => {
    if (newFieldName.trim() !== "") {
      setAllInputsTypes([
        ...allInputsTypes,
        { value: newFieldName, type: newFieldType, optional: optionalField },
      ]);
      setNewFieldName("");
      setNewFieldType("text");
      setOptionalField(false);
      toggleInputFields();
      allData.data[allData.index].forms.push({
        value: newFieldName,
        type: newFieldType,
        optional: optionalField,
      });
      close();
    }
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth={"true"}>
      <DialogTitle className="dialog-title">Add Field</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={4} marginTop={"10px"}>
            <>
              <TextField
                label="Name"
                variant="outlined"
                value={newFieldName}
                onChange={(e) => handleNameChange(e)}
                inputProps={{
                  maxLength: 15,
                }}
              />
              <Typography
                variant="body2"
                color={isNameValid ? "textPrimary" : "error"}
                style={{ margin: "10px 0 0 20px" }}
              >
                {isNameValid ? "Valid" : "Not Valid"}
              </Typography>
            </>

            <FormControl variant="outlined">
              <InputLabel id="field-type-label">Type</InputLabel>
              <Select
                labelId="field-type-label"
                id="demo-simple-select"
                value={newFieldType}
                onChange={(e) => setNewFieldType(e.target.value)}
                label="Type"
                autoWidth
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="tel">Phone</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </Select>
            </FormControl>
            <Stack
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <p>Make this input mandatory</p>

              <IOSSwitch
                checked={optionalField === true}
                onChange={(e) =>
                  setOptionalField(e.target.checked ? true : false)
                }
              />
            </Stack>
          </Stack>

          <DialogActions className="dialog-btns">
            <DeleteButton func={close} text="close"></DeleteButton>
            <DesignButton
              func={addInputField}
              text="add"
              dis={!isNameValid}
            ></DesignButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewField;
