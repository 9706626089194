import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Box, Typography, Menu, MenuItem, Button } from "@mui/material";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";

import Styles from "./ChatsHead.module.css";

const ChatsHead = () => {
  const params = useParams();
  const accountNumber = params.accNum;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={Styles.headerCont}>
      <Box className={Styles.botBuilderCont}>
        <h1 className={Styles.titleFirst}>Bot Builder</h1>
        <Typography className={Styles.discriptionFirst}>
          Manage your bots, or build new ones for different channels or web
          pages. Every single bot under your account is listed here.
        </Typography>
      </Box>
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className={Styles.designBtn}
        >
          Build a Bot
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Link to={`/templates/${accountNumber}`}>
            <MenuItem className={Styles.theWholeMenu}>
              <Box className={Styles.menuCont}>
                <Box className={Styles.addIconCont}>
                  <AddCircleTwoToneIcon
                    className={Styles.addIcon}
                  ></AddCircleTwoToneIcon>
                </Box>
                <Box>
                  <Typography className={`${Styles.noMargin}`}>
                    Build an inbound Bot
                  </Typography>
                  <Typography
                    className={`${Styles.noMargin} ${Styles.secondParagraph}`}
                  >
                    Bot responds to incoming queries
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Link>
        </Menu>
      </Box>
    </Box>
  );
};

export default ChatsHead;
