import RoutesProvider from "./RoutesProvider";
import Navigations from "../navigations/Navigations";
import { Fragment } from "react";
import ResponsiveMessage from "../features/responsive/ResponsiveMessage";

const AppProvider = () => {
  return (
    <Fragment>
      <ResponsiveMessage />
      <RoutesProvider>
        <Navigations />
      </RoutesProvider>
    </Fragment>
  );
};

export default AppProvider;
