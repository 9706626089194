import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "../features/charts/components/SideBar/SideBar";
import routes from "./routes";

const ProtectedRoutes = () => {
  const localData = localStorage.getItem("AC");
  return (
    <Fragment>
      {localData && localData !== "" ? (
        <div className="page d-flex">
          <SideBar></SideBar>
          <div className="content w-full" style={{ paddingLeft: "65px" }}>
            <Outlet></Outlet>
          </div>
        </div>
      ) : (
        <Navigate to={routes.login} />
      )}
    </Fragment>
  );
};

export default ProtectedRoutes;
