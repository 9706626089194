import { Routes, Route } from "react-router-dom";
import routes from "./routes";

import ViewChatBot from "../features/viewer/pages/InterviewChatbot";
import Design from "../features/design/pages/Design";
import Login from "../features/Auth/page/Login";
import CheckEmail from "../features/Auth/page/forrgetPassword/components/CheckEmail";
import OtpCheckEmail from "../features/Auth/page/forrgetPassword/components/OtpCheckEmail";
import NewPassword from "../features/Auth/page/forrgetPassword/components/NewPassword";
import Register from "../features/Auth/page/Register";
import ChatsPage from "../features/charts/Page/ChatsPage";
import AllChats from "../features/allChats/page/AllChats";
import EmbededChat from "../features/viewer/components/EmbededChat";

import SendMsgUse from "../features/howToUse/SendMsgUse";
import CollectInputUse from "../features/howToUse/CollectInputUse";
import ButtonsUse from "../features/howToUse/ButtonsUse";
import Calendar from "../features/howToUse/Calendar";
import SendEmailUse from "../features/howToUse/SendEmailUse";
import ImgCarouselUse from "../features/howToUse/ImgCarouselUse";
import SliderUse from "../features/howToUse/SliderUse";
import FileUploadUse from "../features/howToUse/FileUploadUse";
import DelayUse from "../features/howToUse/DelayUse";
import FormUse from "../features/howToUse/FormUse";
import ProtectedRoutes from "./ProtectedRoutes";
import DocLayOut from "./DocLayOut";
import ProtectedDesign from "./designProtected/ProtectedDesign";
import ChatTemplates from "../features/chatTemplates/page/ChatTemplates";
import CarousalUse from "../features/howToUse/CarousalUse";

const Navigations = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />}></Route>
      <Route path={routes.register} element={<Register />}></Route>
      <Route path={routes.checkEmail} element={<CheckEmail />}></Route>
      <Route path={routes.otpCheckEmail} element={<OtpCheckEmail />}></Route>
      <Route path={routes.newPassword} element={<NewPassword />}></Route>
      <Route path={routes.embeded} element={<EmbededChat />}></Route>

      <Route element={<ProtectedRoutes />}>
        <Route path={routes.charts} element={<ChatsPage />}></Route>
        <Route path={routes.allChats} element={<AllChats />}></Route>
        <Route path={routes.templates} element={<ChatTemplates />}></Route>
      </Route>

      <Route element={<ProtectedDesign />}>
        <Route path={routes.dashboard} element={<Design />}></Route>
        <Route path={routes.newDashboard} element={<Design />}></Route>
        <Route path={routes.viewer} element={<ViewChatBot />}></Route>
      </Route>

      <Route element={<DocLayOut />}>
        <Route path={routes.sendMsgUse} element={<SendMsgUse />}></Route>
        <Route
          path={routes.collectInpUse}
          element={<CollectInputUse />}
        ></Route>
        <Route path={routes.buttonsUse} element={<ButtonsUse />}></Route>
        <Route path={routes.calendarUse} element={<Calendar />}></Route>
        <Route path={routes.sendEmailUSe} element={<SendEmailUse />}></Route>
        <Route
          path={routes.ImgCarouselUse}
          element={<ImgCarouselUse />}
        ></Route>
        <Route path={routes.SliderUse} element={<SliderUse />}></Route>
        <Route path={routes.FileUploadUse} element={<FileUploadUse />}></Route>
        <Route path={routes.DelayUse} element={<DelayUse />}></Route>
        <Route path={routes.FormUse} element={<FormUse />}></Route>
        <Route path={routes.CarouselUse} element={<CarousalUse />}></Route>
      </Route>
    </Routes>
  );
};

export default Navigations;
