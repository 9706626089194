import React, { useEffect, useState } from "react";

import SliderHeader from "../../../../components/ui/sliderHeader/SliderHeader";

import { Box } from "@mui/material";

import ExcludeDates from "./components/ExcludeDates";
import ExcludeTimes from "./components/ExcludeTimes";

import "../styles/slider.css";

const CalenderModal = ({ allData }) => {
  const [excludedDaysAndTimes, setExcludedDaysAndTimes] = useState("");

  const handleExcludedDaysAndTimesChange = (newExcludedDaysAndTimes) => {
    setExcludedDaysAndTimes(newExcludedDaysAndTimes);
  };

  useEffect(() => {
    const initialExcludedDays = {};
    for (let i = 0; i < 7; i++) {
      initialExcludedDays[i] = {
        from: allData.data[allData.index].defaultFrom,
        to: allData.data[allData.index].defaultTo,
      };
    }
    if (allData.data[allData.index].timing.length === 0) {
      setExcludedDaysAndTimes(initialExcludedDays);
      allData.data[allData.index].timing = initialExcludedDays;
    } else {
      setExcludedDaysAndTimes(allData.data[allData.index].timing);
    }
  }, [allData.data, allData.index]);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [dateType, setDateType] = useState(
    allData.data[allData.index].calenderType
  );

  useEffect(() => {
    setDateType(allData.data[allData.index].calenderType);
  }, [allData.data, allData.index]);

  const handleDateType = (e) => {
    const type = e.target.id;
    setDateType(type);
    allData.data[allData.index].calenderType = type;
  };

  useEffect(() => {
    const fromMinutes = convertTimeToMinutes(
      allData.data[allData.index].defaultFrom
    );
    const toMinutes = convertTimeToMinutes(
      allData.data[allData.index].defaultTo
    );

    console.log("from mt3sbne4", fromMinutes, "to", toMinutes);

    // Check if defaultTo is less than or equal to defaultFrom
    if (toMinutes <= fromMinutes) {
      const newError = {
        id: allData.data[allData.index].data.id,
        title: allData.data[allData.index].data.label || "Untitled",
        subject: "To must not be the same value as or less than From",
      };

      // Add the new error to the errors state
      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(error.id === newError.id && error.subject === newError.subject)
        );

        return [...updatedErrors, newError];
      });
    } else {
      // Remove the specific error object
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                `To must not be the same value as or less than From`
            )
        )
      );
    }
  }, [
    allData.data[allData.index].defaultTo,
    allData.data[allData.index].defaultFrom,
    allData.data[allData.index].data.label,
  ]);

  useEffect(() => {
    const dayData =
      excludedDaysAndTimes[
        allData.data[allData.index].selectedSpecificTimeToDate
      ];
    if (dayData && dayData.to <= dayData.from) {
      const newError = {
        id: allData.data[allData.index].data.id,
        title: allData.data[allData.index].data.label || "Untitled",
        subject: `To mustn't be the same value as To or less than it in Specific Day`,
      };

      // Add the new error to the errors state
      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(error.id === newError.id && error.subject === newError.subject)
        );

        return [...updatedErrors, newError];
      });
    } else {
      // Remove the specific error object
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                `To mustn't be the same value as To or less than it in Specific Day`
            )
        )
      );
    }
  }, [
    excludedDaysAndTimes[allData.data[allData.index].selectedSpecificTimeToDate]
      ?.to,
    excludedDaysAndTimes[allData.data[allData.index].selectedSpecificTimeToDate]
      ?.from,
    allData.index,
    allData.data[allData.index].selectedSpecificTimeToDate,
    allData.data[allData.index].data.label,
  ]);

  const convertTimeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };

  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Opens a calendar UI in the chat interface through which the user can select their desired dater"
        }
        toastHeader="Calendar"
        nodeType={allData.nodeType}
      ></SliderHeader>

      <Box className="slider-comp">
        <Box className="message-box">
          <Box className="date-selection slider-comp">
            <button
              id="dates"
              onClick={handleDateType}
              style={{
                color: dateType === "dates" ? "#7D7D7D" : "#333333",
                borderBottom:
                  dateType === "dates" ? "3px solid #7D7D7D" : "none",
                cursor: "pointer",
              }}
            >
              Exclude Dates
            </button>
            <button
              id="times"
              onClick={handleDateType}
              style={{
                color: dateType === "times" ? "#7D7D7D" : "#333333",
                borderBottom:
                  dateType === "times" ? "3px solid #7D7D7D" : "none",
                cursor: "pointer",
              }}
            >
              Exclude Times
            </button>
          </Box>
          {dateType === "dates" ? (
            <ExcludeDates
              allData={allData}
              daysOfWeek={daysOfWeek}
              setExcludedDaysAndTimes={handleExcludedDaysAndTimesChange}
            />
          ) : (
            <ExcludeTimes
              allData={allData}
              daysOfWeek={daysOfWeek}
              excludedDaysAndTimes={excludedDaysAndTimes}
              setExcludedDaysAndTimes={handleExcludedDaysAndTimesChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CalenderModal;
