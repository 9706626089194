import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import AddIcon from "@mui/icons-material/Add";

const CarousalButtons = ({
  allData,
  selectedCardOptions,
  optionIndx,
  handleCardClick,
}) => {
  const [linkPopupOpen, setLinkPopupOpen] = useState(false);
  const [linkValue, setLinkValue] = useState("");
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  useEffect(() => {
    if (selectedButtonIndex !== null) {
      setLinkValue(selectedCardOptions.buttons[selectedButtonIndex].URL);
      setOpenInNewTab(selectedCardOptions.buttons[selectedButtonIndex].newTab);
    }
  }, [selectedButtonIndex]);

  // Function to handle opening the link popup
  const handleOpenLinkPopup = (buttonIndex) => {
    setLinkPopupOpen(true);
    setSelectedButtonIndex(buttonIndex);
  };

  // Function to handle closing the link popup
  const handleCloseLinkPopup = () => {
    setLinkPopupOpen(false);
    setSelectedButtonIndex(null);
  };

  // Function to handle saving the link and closing the popup
  const handleSaveLink = () => {
    selectedCardOptions.buttons[selectedButtonIndex].URL = linkValue;
    selectedCardOptions.buttons[selectedButtonIndex].newTab = openInNewTab;
    allData.reRender();
    handleCloseLinkPopup();
  };

  const currentIndex = allData.data[allData.index].options.findIndex(
    (card) => card.cardId === selectedCardOptions.cardId
  );
  const nextIndex = (currentIndex + 1) % allData.data.length;
  const previousIndex =
    (currentIndex - 1 + allData.data.length) % allData.data.length;

  const handleNextCard = () => {
    handleCardClick(nextIndex);
  };

  const handlePreviousCard = () => {
    handleCardClick(previousIndex);
  };
  return (
    <Fragment>
      <Box style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "900",
            marginBottom: "20px",
          }}
        >
          Buttons
        </Typography>
        {selectedCardOptions &&
          selectedCardOptions?.buttons?.map((button, index) => {
            const matchingElement = allData.data.find(
              (element) => element.id === button.id
            );

            return (
              <Box
                key={index}
                style={{
                  marginBottom: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  {button.type === "BranchButton" ? (
                    <Box style={{ flex: "2" }}>
                      <TextField
                        label="Branch Button Name"
                        style={{ width: "100%" }}
                        multiline
                        inputProps={{ maxLength: 75 }}
                        value={matchingElement?.data?.label}
                        onChange={(e) => {
                          matchingElement.data.label = e.target.value;
                          allData.data[allData.index].options[
                            optionIndx
                          ].buttons[index].value = e.target.value;
                          allData.reRender();
                        }}
                      ></TextField>
                    </Box>
                  ) : (
                    <Box style={{ flex: "2" }}>
                      <TextField
                        label="URL Button Name"
                        style={{ width: "100%" }}
                        multiline
                        inputProps={{ maxLength: 75 }}
                        value={matchingElement?.data?.label}
                        onChange={(e) => {
                          matchingElement.data.label = e.target.value;
                          allData.data[allData.index].options[
                            optionIndx
                          ].buttons[index].value = e.target.value;
                          allData.reRender();
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InsertLinkIcon
                                onClick={() => handleOpenLinkPopup(index)}
                                style={{ cursor: "pointer" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </Box>
                  )}

                  {selectedCardOptions.buttons.length > 1 ? (
                    <Box style={{ cursor: "pointer" }}>
                      <DeleteOutlinedIcon
                        className="delete-outlined-icon"
                        onClick={() => {
                          const indexToRemove = allData.pathData.findIndex(
                            (pathItem) =>
                              pathItem.target ===
                              selectedCardOptions.buttons[index].id
                          );
                          allData.pathData.splice(indexToRemove, 1);
                          allData.reRender();

                          const indexToRemoveNodes = allData.data.findIndex(
                            (dataItem) =>
                              dataItem.id ===
                              selectedCardOptions.buttons[index].id
                          );
                          allData.data.splice(indexToRemoveNodes, 1);
                          allData.reRender();

                          selectedCardOptions.buttons.splice(index, 1);
                          allData.reRender();
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
                <Typography
                  style={{
                    fontSize: "11px",
                    color: "#858585",
                    marginTop: "6px",
                  }}
                >
                  {button.type === "BranchButton"
                    ? "Add new Blocks from the port to configure it"
                    : "Click on the URL icon to add the URL"}
                </Typography>
              </Box>
            );
          })}
        {selectedCardOptions.buttons.length <= 2 && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Button
              className="add-branch-btn"
              startIcon={<AddIcon />}
              onClick={() =>
                allData.addButtonsToTheCards(
                  selectedCardOptions.cardId,
                  allData.index,
                  "Branch Button"
                )
              }
            >
              Branch Button
            </Button>
            <Button
              className="add-URL-btn"
              startIcon={<AddIcon />}
              onClick={() =>
                allData.addButtonsToTheCards(
                  selectedCardOptions.cardId,
                  allData.index,
                  "URL Button"
                )
              }
            >
              URL Button
            </Button>
          </Box>
        )}
        {/* Link popup */}
        <Dialog open={linkPopupOpen} onClose={handleCloseLinkPopup}>
          <DialogTitle>Add Link</DialogTitle>
          <DialogContent>
            <TextField
              label="Link"
              value={linkValue}
              onChange={(e) => setLinkValue(e.target.value)}
              fullWidth
              style={{ marginTop: "10px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={openInNewTab}
                  onChange={() => setOpenInNewTab(!openInNewTab)}
                />
              }
              label="Open in new tab"
              style={{ marginTop: "10px", marginLeft: "10px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLinkPopup}>Cancel</Button>
            <Button onClick={handleSaveLink}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "120px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            visibility:
              allData.data.length > 1 &&
              selectedCardOptions.cardId !==
                allData.data[allData.index].options[0].cardId
                ? "visible"
                : "hidden",
          }}
        >
          <Box
            onClick={handlePreviousCard}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#2a7cff",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </Box>
            <Box>
              <Typography>{`Card ${previousIndex + 1}`}</Typography>
            </Box>
          </Box>
        </Box>

        <Box style={{ fontSize: "16px" }}>
          <Typography>{`${currentIndex + 1}/${
            allData.data[allData.index].options.length
          }`}</Typography>
        </Box>

        <Box
          style={{
            visibility:
              allData.data.length > 1 &&
              selectedCardOptions.cardId !==
                allData.data[allData.index].options[
                  allData.data[allData.index].options.length - 1
                ].cardId
                ? "visible"
                : "hidden",
          }}
        >
          <Box
            onClick={handleNextCard}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#2a7cff",
            }}
          >
            <Box>
              <Typography>{`Card ${nextIndex + 1}`}</Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <KeyboardArrowRightOutlinedIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CarousalButtons;
