import React, { Fragment, useEffect, useState } from "react";
import Chat from "../components/ChatModal";
import ChatSound from "../../../assets/audios/openChat.wav";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "./styles/InterviewChatbot.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    const handleBackButton = () => {
      // Perform your custom actions here
      console.log("Custom action on back button in Home");
    };

    // Attach the event listener to the popstate event
    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount
  // const pars = useParams();

  // const chatId = pars.chatId;

  // const addToLocalStorage = (key, value) => {
  //   localStorage.setItem(key, JSON.stringify(value));
  // };

  // useEffect(() => {
  //   addToLocalStorage("newChatIdDesign", chatId);
  // }, [chatId]);
  const chatSound = new Audio(ChatSound);
  const playChatSound = () => {
    chatSound.play();
  };
  const [showChatBox, setShowChatBox] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    toast.success("Data saved successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  return (
    <Fragment>
      <ToastContainer />
      <Box className="viewer-container">
        {showChatBox && (
          <Box className="chatBot">
            <Chat />
          </Box>
        )}
        <Box
          className="chatBotIconContainer"
          onClick={() => {
            setShowChatBox((prev) => !prev);
            setIsAnimating((prev) => !prev); // Toggle the animation on click
            if (!showChatBox) {
              playChatSound(); // Play the sound when the chat box is shown
            }
          }}
        >
          <Box className={`chatBotIcon ${isAnimating ? "bounce" : ""}`}>
            <img
              className="niyat-icon"
              src="https://wotnot-chat-widget-icon.storage.googleapis.com/bff4c2ca-5276-11ee-8191-eee1d433cc87_Artboard%201%402x.png"
              alt=""
            ></img>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Home;
