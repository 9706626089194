import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import Niyat_logo from "../../../assets/images/logo.jpg";

import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  TextField,
  Button,
  OutlinedInput,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import axios from "axios";

import { baseURL } from "../../../axiosApi/axiosApi.js";

import Styles from "./Register.module.css";

const Register = () => {
  const [email, setEmail] = useState();
  const [password, setpassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [nameEng, setNameEng] = useState();
  const [nameAr, setNameAr] = useState();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };

  const signUpSubmit = async (e) => {
    const values = {
      UserName: email,
      password: password,
      confirmPassword: confirmPassword,
      FullNameEng: nameEng,
      FullNameAr: nameAr,
    };
    console.log("values", JSON.stringify(values));
    try {
      const response = await axios.get(
        `${baseURL}/chatbot/KBot/CreatNewUsers?userdata=${JSON.stringify(
          values
        )}`
      );
      if (response.status === 200) {
        // navigate("/login");
        const jsonString = response.data[0].jsonData;
        const jsonObject = JSON.parse(jsonString);
        if (jsonObject.Rigister === "User Alrady Exist") {
          toast.warn(`This Email Is Already Exist !`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          navigate("/login");
        }
      } else {
        console.log("Request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfrimPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = async (e, type) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    setValue(type, newEmail);
    await trigger(type);
  };

  const handlePassChange = async (e, type) => {
    const newPass = e.target.value;
    setpassword(newPass);

    setValue(type, newPass);
    await trigger(type);
  };

  const handleConfirmPassChange = async (e, type) => {
    const newConfirmPass = e.target.value;
    setConfirmPassword(newConfirmPass);

    setValue(type, newConfirmPass);
    await trigger(type);
  };

  const handleNameEnChange = async (e, type) => {
    const EnglishName = e.target.value;
    setNameEng(EnglishName);

    setValue(type, EnglishName);
    await trigger(type);
  };

  const handleNameArChange = async (e, type) => {
    const ArabicName = e.target.value;
    setNameAr(ArabicName);

    setValue(type, ArabicName);
    await trigger(type);
  };

  const passwordValidation = (value) => {
    const confirmPasswordValue = value || "";
    const enteredPassword = password || "";

    return confirmPasswordValue === enteredPassword || "Passwords do not match";
  };

  const watchPassword = watch("password");

  useEffect(() => {
    if (watchPassword) {
      trigger("confirmPassword");
    }
  }, [watchPassword, trigger]);

  const isPasswordValid = (value) => {
    // Add your custom password validation logic here
    const containsCharacters = /[a-zA-Z]/.test(value);
    const containsSpecialCases = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const containsNumbers = /\d/.test(value);
    const containsUpperCaseLetter = /[A-Z]/.test(value);

    return (
      containsCharacters &&
      containsSpecialCases &&
      containsNumbers &&
      containsUpperCaseLetter
    );
  };

  return (
    <>
      <ToastContainer />
      <Box className={Styles.registerCont}>
        <Box className={Styles.formCont}>
          <img src={Niyat_logo} alt="niyat-logo"></img>
          <Box className={Styles.words}>
            <Typography className={Styles.title}>Welcome</Typography>
            <Typography className={Styles.someTools}>
              Sign up and build some cool bots
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(signUpSubmit)}>
            <FormControl isInvalid={errors.email}>
              <Typography>Email <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Wrong Email Pattern",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "Cannot be only spaces",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => {
                      handleEmailChange(e, "email");
                    }}
                    type="email"
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.email && errors.email.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <Typography>Password <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  minLength: {
                    value: 8,
                    message: "Minimum length is 8 characters",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "It Mustn't contain only spaces",
                    passwordRequirements: (value) =>
                      isPasswordValid(value) ||
                      "Password must contain characters, special cases, numbers, and an uppercase letter",
                  },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    onChange={(e) => {
                      handlePassChange(e, "password");
                    }}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.password && errors.password.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.confirmPassword}>
              <Typography>Confirm Password <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "It Mustn't contains only spaces",
                    passwordMatch: passwordValidation,
                  },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    onChange={(e) => {
                      handleConfirmPassChange(e, "confirmPassword");
                    }}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfrimPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.confirmPassword && errors.confirmPassword.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.nameEng}>
              <Typography>Name in English <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="nameEng"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  minLength: {
                    value: 2,
                    message: "Minimum length is 2 characters",
                  },
                  maxLength: {
                    value: 25,
                    message: "Maximum length is 25 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$|^[0-9]+$/,
                    message: "Invalid name format",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "Cannot be only spaces",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => {
                      handleNameEnChange(e, "nameEng");
                    }}
                    type="nameEng"
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.nameEng && errors.nameEng.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.nameAr}>
              <Typography>Name in Arabic <span className={Styles.MandatoryStars}>*</span></Typography>
              <Controller
                name="nameAr"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  minLength: {
                    value: 2,
                    message: "Minimum length is 2 characters",
                  },
                  maxLength: {
                    value: 25,
                    message: "Maximum length is 25 characters",
                  },
                  pattern: {
                    value: /^[\s\u0600-\u06FF]+$/,
                    message: "Invalid name format",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "Cannot be only spaces",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => {
                      handleNameArChange(e, "nameAr");
                    }}
                    type="nameAr"
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.nameAr && errors.nameAr.message}
              </Typography>
            </FormControl>

            <Button className={Styles.signUp} variant="contained" type="submit">
              Sign up
            </Button>
          </form>
          
        </Box>
      </Box>
    </>
  );
};

export default Register;
