import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, MenuItem, Menu, Fade } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Styles from "./Chats.module.css";

const options = ["Edit", "Delete", "Share"];

const MenuOptions = ({ chat, setIsDeleteDialogOpen, setIsShareDialogOpen }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const accountNumber = params.accNum;
  const ITEM_HEIGHT = 48;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
    handleClose();
  };

  const openShareDialog = () => {
    setIsShareDialogOpen(true);
    handleClose();
  };

  return (
    <Box className={Styles.rightCard}>
      <Box className={Styles.moreIcon}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
          TransitionComponent={Fade}
        >
          {options.map((option, index2) => (
            <MenuItem
              key={index2}
              onClick={() => {
                option === "Delete" && openDeleteDialog();
                option === "Edit" &&
                  navigate(`/design/${accountNumber}/${chat.ChatId}`);
                option === "Share" && openShareDialog();
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/*Next Phase*/}
      {/*<Box>
          <IOSSwitch
          // checked={optionalCountStep === true}
          // onChange={handleChangeCheckedStep}
        />
          </Box>*/}
    </Box>
  );
};

export default MenuOptions;
