import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { Link } from "react-scroll";
import Styles from "./styles/instructions.module.css";

const SendMsgUse = () => {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box sx={{ display: "flex", marginTop: "64px" }} style={{width:"100%"}}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Send Message</h1>
          <Typography className={Styles.mb20}>
            The "Send Message" action block allows you to engage with your
            visitors by sending messages in a chat conversation.
          </Typography>

          {/*<Typography className="mb10">
            The rich text editor supports all the languages i.e. now you can
            type your input in any language you want like French, Spanish etc..
          </Typography>*/}

          <Box className={Styles.importantText}>
            Delay in sending messages on the basis of word count like if the
            content of the first message is large, the bot usually delays
            sending the second message for 2 to 5 seconds allowing the visitor
            time to read through the first message.
          </Box>

          <h3 id="1"> When should I use this block?</h3>
          <Typography>
            There are various reasons for which this action block can be used
            like for instances it allows you to greet your visitor, share
            information about the product or service or company as well.
          </Typography>

          <h3 id="2" className={Styles.followInstructions}>
            How do I set it up?
          </h3>
          <h3 className={Styles.followInstructions}>
            You can follow the following steps to set up 'Send Message' action
            block:
          </h3>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20} id="3">
              <h4>Step 1: Add the Action Block</h4>
              <Typography>Add an block on canvas by clicking on '+'</Typography>
            </li>
            <li className={Styles.mb20} id="4">
              <h4>Step 2: Select "Send Message"</h4>
              <Typography>
                Choose "Send Message" action block either from the list or by
                using the search field
              </Typography>
            </li>
            <li className={Styles.mb20} id="5">
              <h4>Step 3: Open Configuration Panel</h4>
              <Typography>
                Double click on the "Send Message" action block to open the
                configuration panel on the right
              </Typography>
            </li>
            <li className={Styles.mb20} id="6">
              <h4>Step 4: Rename the Block</h4>
              <Typography>
                Rename the block according to your preference. This name will
                serve as a "Goal" for insights into the visitor journey on the
                analytics dashboard
              </Typography>
            </li>
            <li className={Styles.mb20} id="7">
              <h4>Step 5: Configure the Message</h4>
              <Typography>
                Use the rich text editor to configure the message. You can
                format the text using various options. Add text blocks by
                clicking on '+ Text' and include PDF/JPG files by clicking on '+
                File'
              </Typography>
            </li>
            {/*<li >
              <h4>Step 6: Use "Send this message as an info title"</h4>
              <Typography>
                Click "Send this message as info title" if you want to act as a
                title. When clicked, it will reveal the message configured under
                it.
              </Typography>
            </li>*/}
          </ul>
          <h3 id="8">What formatting options do I have?</h3>
          <Typography className={Styles.mb20}>
            In the rich text editor, you can format your text using the
            formatting bar. It allows you to display your message to the user
            either in the Bold or Italics letters or even underline the selected
            part of the message. It also allows you to send the message in the
            bullet points or attach link with selected text.
          </Typography>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb10}>Bold</li>
            <li className={Styles.mb10}>Italics</li>
            <li className={Styles.mb10}>Underline</li>
            <li className={Styles.mb10}>Bullet Points</li>
            <li className={Styles.mb10}>Link</li>
          </ul>

          <Box className={Styles.importantText}>
            We do support up to 999 characters however we do recommend not to
            have more than 160 characters in a single message since anything
            more than this could impede the user experience
          </Box>
          <h3 id="9">Can the messages be re-arranged? </h3>
          <Typography className={Styles.mb40}>
            The sequence of the messages / inputs space can also be changed as
            per the preference. You can hover over the block , left click on
            arrow (hold), drag (upwards or downwards) & release it to the
            position as per the new sequence defined.
          </Typography>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should i use this block?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should i use this block?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Step 1: Add the Action Block" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 3 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="3"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(3)}
                >
                  Step 1: Add the Action Block
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title={`Step 2: Select "Send Message"`} placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 4 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="4"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(4)}
                >
                  Step 2: Select "Send Message"
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Step 3: Open Configuration Panel" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 5 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="5"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(5)}
                >
                  Step 3: Open Configuration Panel
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Step 4: Rename the Block" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 6 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="6"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(6)}
                >
                  Step 4: Rename the Block
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Step 5: Configure the Message" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 7 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="7"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(7)}
                >
                  Step 5: Configure the Message
                </Link>
              </Box>
            </Tooltip>
            <Tooltip
              title="What formatting options do i have?"
              placement="left"
            >
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 8 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="8"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(8)}
                >
                  What formatting options do i have?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="Can the messages be re-arranged?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 9 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="9"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(9)}
                >
                  Can the messages be re-arranged?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SendMsgUse;
