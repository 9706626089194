import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const DelayUse = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Delay</h1>
          <Typography className={Styles.mb20}>
            This action block allows you to configure wait time between the two
            messages sent by the bot
          </Typography>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/FileUpload`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>File upload</Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/Carousel`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Carousel</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DelayUse;
