import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const FileUploadUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box sx={{ display: "flex", marginTop: "64px" }} style={{width:"100%"}}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>File Upload</h1>
          <Typography className={Styles.mb20}>
            This action block is used when you would like your visitors to
            submit documents or files through bot.
          </Typography>

          <h3 id="1"> In what formats does the file can be uploaded?</h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>.pdf</li>
            <li className={Styles.mb20}>.jpg</li>
            <li className={Styles.mb20}>.png</li>
            <li className={Styles.mb20}>.doc</li>
            <li className={Styles.mb20}>.docx</li>
            <li className={Styles.mb20}>.ppt</li>
            <li className={Styles.mb20}>.xsl</li>
            <li className={Styles.mb20}>.xlsx</li>
            <li className={Styles.mb20}>.csv</li>
          </ul>

          <Box className={Styles.importantText}>
            Maximum size of the file supported is 10 MB Maximum number of
            uploads allowed is up to 10
          </Box>

          <h3 id="2"> How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up the 'File Upload'
            action block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block on canvas by clicking on '+'
            </li>
            <li className={Styles.mb20}>Choose 'File Upload'</li>
            <li className={Styles.mb20}>
              Double click on 'File Upload' to configure it on the right panel
            </li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Message: </h4>
                <Typography>
                  Define the message or question that will appear to your
                  visitors before collecting the file.
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Type of File:</h4>
                <Typography>
                  Select the type of file that you want to limit the visitors to
                  upload. This will restrict the bot from accepting the files of
                  the format which are not selected by you.
                </Typography>
              </li>
              {/*<li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Define Variable: </h4>
                <Typography>
                  Configure the variable where you would like the file to be
                  uploaded or stored. This will allow you to share the link of
                  the file uploaded by the visitor later in the flow.
                </Typography>
              </li>*/}
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>File Size:</h4>
                <Typography>
                  Set the limit of the file size that the visitor can upload.
                  The maximum size supported is 10 MB. Other sizes that you can
                  choose are 1, 2 & 5 MB. In-order to change the maximum size of
                  the file, you will need to click on 'Drop down arrow' & select
                  from the available options.
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Upload Limit: </h4>
                <Typography>
                  Set the upload limit i.e the number of times visitor can
                  upload a file. Maximum number of uploads allowed per visitor
                  is 10. The other available options are 1, 2 & 5. In-order to
                  change the upload limit, you will need to click on 'Drop down
                  arrow' & select from the available options.
                </Typography>
              </li>
            </ul>
          </ul>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/Slider`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>Slider</Typography>
              </Box>
            </Box>
            <Box className={Styles.nextCont} onClick={() => navigate(`/Delay`)}>
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Delay</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip
              title="In what formats does the file can be uploaded? "
              placement="left"
            >
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  In what formats does the file can be uploaded?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadUse;
