import React, { useState } from "react";
import logo from "../../../../assets/images/logo.jpg";
import { BiBarChartSquare } from "react-icons/bi";
import { IoMdContacts } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineBookOpen } from "react-icons/hi";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import { FaPlug } from "react-icons/fa6";
import { HiOutlinePuzzlePiece } from "react-icons/hi2";
import { IoMdContact } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { LuHelpCircle } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import "./SideBar.css";

const SideBar = () => {
  const navigate = useNavigate();
  const params = useParams();
  const accountNumber = params.accNum;

  const secondAccNum = localStorage.getItem("AC");

  const [activeItem, setActiveItem] = useState(`/`);

  const handleItemClick = (path) => {
    navigate(path);
    setActiveItem(path);
  };
  return (
    <Box className="sidebar bg-sidebar">
      <Box>
        <h3
          className="p-relative"
          style={{ textAlign: "center", marginTop: "5px" }}
        >
          <img
            src={logo}
            alt="p"
            style={{ width: "60px", cursor: "pointer", marginLeft: "5px" }}
            onClick={() => handleItemClick(`/`)}
          ></img>
        </h3>

        <ul className="side-list">
          <Tooltip title="Analytics Dashboard" placement="right">
            <li
              onClick={() => handleItemClick(`/`)}
              className={`side-li ${
                activeItem === `/` ? "active" : ""
              }`}
            >
              <Box className="icon-cont">
                <BiBarChartSquare size={25}></BiBarChartSquare>
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Live Chat" placement="right">
            <li
              onClick={() => setActiveItem("messege")}
              className={`side-li ${activeItem === "messege" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <FiMessageSquare size={25} />
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Ai Studio" placement="right">
            <li
              onClick={() => setActiveItem("book")}
              className={`side-li ${activeItem === "book" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <HiOutlineBookOpen size={27} />
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Bot Builder" placement="right">
            <li
              onClick={() => handleItemClick(`/allChats/${secondAccNum}`)}
              className={`side-li ${
                activeItem === `/allChats/${secondAccNum}` ? "active" : ""
              }`}
            >
              <Box className="icon-cont">
                <LiaProjectDiagramSolid size={25} />
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Contacts" placement="right">
            <li
              onClick={() => setActiveItem("contacts")}
              className={`side-li ${activeItem === "contacts" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <IoMdContacts size={25} />
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Channel Configuration" placement="right">
            <li
              onClick={() => setActiveItem("plug")}
              className={`side-li ${activeItem === "plug" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <FaPlug size={25} />
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="App Market" placement="right">
            <li
              onClick={() => setActiveItem("puzzle")}
              className={`side-li ${activeItem === "puzzle" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <HiOutlinePuzzlePiece size={25} />
              </Box>
            </li>
          </Tooltip>
        </ul>
      </Box>
      <Box>
        <ul className="side-list">
          <Tooltip title="Help Desk" placement="right">
            <li
              onClick={() => setActiveItem("help")}
              className={`side-li ${activeItem === "help" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <LuHelpCircle size={25}></LuHelpCircle>
              </Box>
            </li>
          </Tooltip>

          <Tooltip title="Settings" placement="right">
            <li
              onClick={() => setActiveItem("settings")}
              className={`side-li ${activeItem === "settings" ? "active" : ""}`}
            >
              <Box className="icon-cont">
                <IoSettingsSharp size={25}></IoSettingsSharp>
              </Box>
            </li>
          </Tooltip>
          <li>
            <Box className="pro">
              <IoMdContact size={30}></IoMdContact>
            </Box>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default SideBar;
