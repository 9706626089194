import React from "react";
import { Box } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import "../../Styles/chatbot.css";

const SendQuillMessage = ({ cont }) => {
  const withoutQuotes = cont.replace(/'/g, "");

  const transform = (node, index) => {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs &&
      node.attribs.href
    ) {
      if (node.attribs.href.startsWith("www.")) {
        node.attribs.href = `http://${node.attribs.href}`;
      }
    }
    return undefined;
  };

  return (
    <Box className="quill-container">
      {ReactHtmlParser(withoutQuotes, { transform })}
    </Box>
  );
};

export default SendQuillMessage;
