import React from "react";
import "../styles/slider.css";

import ImageCaruselModal from "../ImageCaruselModal";
import FormsModal from "../forms/components/modal/FormsModal";
import SendMessage from "../SendMessage";
import CalenderModal from "../calender/CalenderModal";
import ButtonsOptions from "../ButtonsOptions";
import SendAnEmail from "../SendAnEmail";
import SliderOption from "../SliderOption";
import FileUpload from "../FileUpload";
import CollectInput from "../CollectInput";
import Delay from "../Delay";
import CarouselModal from "../Carousel/CarouselModal";

export default function Slide(props) {
  let type;
  if (props.isOpen) type = props.data[props.index]?.data?.type;
  switch (type) {
    default:
      return null;
    case "Send message":
      return <SendMessage allData={props} />;
    case "Send an email":
      return <SendAnEmail allData={props} />;
    case "Calendar":
      return <CalenderModal allData={props} />;
    case "Buttons":
      return <ButtonsOptions allData={props} />;
    case "Image Carousel":
      return <ImageCaruselModal allData={props} />;
    case "Forms":
      return <FormsModal allData={props} />;
    case "Slider":
      return <SliderOption allData={props} />;
    case "File Upload":
      return <FileUpload allData={props} />;
    case "Collect Input":
      return <CollectInput allData={props} />;
    case "Delay":
      return <Delay allData={props} />;
    case "Carousel":
      return <CarouselModal allData={props} />;
  }
}
