import React, { useState, useEffect } from "react";

import SliderHeader from "../../../../../../components/ui/sliderHeader/SliderHeader";
import DesignButton from "../../../../../../components/ui/button/DesignButton";
import Reactquill from "../../../../../../components/ui/reactquill/Reactquill";
import FormDetails from "../FormDetails";
import AddNewField from "../AddNewField";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SwapVertTwoToneIcon from "@mui/icons-material/SwapVertTwoTone";
import { Stack, Button, Box, Typography } from "@mui/material";

import "../../../styles/slider.css";

const DraggableForm = ({
  form,
  index,
  handleDeleteField,
  handleSelectInput,
  moveForm,
  formLength,
}) => {
  return (
    <Draggable draggableId={`formItem-${index}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            opacity: snapshot.isDragging ? 0.5 : 1,
            ...provided.draggableProps.style,
          }}
        >
          <Box className="input-types">
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {/* Add drag handle on the left side */}
              <Box {...provided.dragHandleProps} className="form-drag-handle">
                <SwapVertTwoToneIcon />
              </Box>
              <Box
                className="the-whole-field"
                style={{ display: "flex" }}
                flex={"2"}
              >
                <Button
                  onClick={() => handleSelectInput(form, index)}
                  style={{
                    flex: "2",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box className="single-field">
                    <Box className="field-icon">
                      {form.type === "text" && <TextFieldsOutlinedIcon />}
                      {form.type === "email" && <EmailOutlinedIcon />}
                      {form.type === "tel" && <LocalPhoneOutlinedIcon />}
                      {form.type === "number" && (
                        <FormatListNumberedOutlinedIcon />
                      )}
                      {form.type === "date" && <CalendarMonthOutlinedIcon />}
                    </Box>
                    <Box className="field-name">{form.value}</Box>
                  </Box>
                </Button>
                {formLength !== 1 && (
                  <Box className="delete-field">
                    <DeleteOutlineOutlinedIcon
                      onClick={() => handleDeleteField(index)}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

const FormsModal = ({ allData }) => {
  const [allInputsTypes, setAllInputsTypes] = useState(
    allData?.data[allData.index]?.forms || []
  );

  useEffect(() => {
    setAllInputsTypes(allData?.data[allData.index]?.forms);
  }, [allData.index, allData.data]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editorContent, setEditorContent] = useState([""]);

  useEffect(() => {
    const updatedEditorContent = [...editorContent];
    updatedEditorContent[0] = `<p>${
      allData.data[allData.index].data.description
    }</p>`;
    setEditorContent(updatedEditorContent);
  }, [allData.index, allData.data]);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteField = (index) => {
    const updatedForms = [...allData.data[allData.index].forms];
    updatedForms.splice(index, 1);
    allData.data[allData.index].forms = updatedForms;
    setAllInputsTypes(updatedForms);
  };

  const [selectedInput, setSelectedInput] = useState(null);
  const [selectedIndexInput, setSelectedIndexInput] = useState();

  const handleSelectInput = (input, index) => {
    setSelectedInput(input);
    setSelectedIndexInput(index);
  };

  const handleBackToMainPage = () => {
    setSelectedInput(null); // Clear the selection when going back to the main page
  };

  const moveForm = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedForms = [...allInputsTypes];
    const [movedForm] = updatedForms.splice(result.source.index, 1);
    updatedForms.splice(result.destination.index, 0, movedForm);

    setAllInputsTypes(updatedForms);
    allData.data[allData.index].forms = updatedForms;
    allData.reRender();
  };

  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      {selectedInput ? (
        <FormDetails
          input={selectedInput}
          index={selectedIndexInput}
          onBack={handleBackToMainPage}
          data={allData}
          setAllForms={setAllInputsTypes}
          allData={allData}
        />
      ) : (
        <>
          <SliderHeader
            closeData={allData}
            headerMessage={
              "Quickens the collection of multiple inputs by providing a form"
            }
            toastHeader="Forms"
            nodeType={allData.nodeType}
          ></SliderHeader>

          {editorContent.map((content, index2) => (
            <Box className="slider-comp">
              <Box className="disc">
                <p className="message-description">Show message</p>
              </Box>
              <Reactquill
                ediotrs={editorContent}
                cont={content}
                index={index2}
                Data={allData}
                placehold="Please fill in the following details"
              ></Reactquill>
            </Box>
          ))}

          <Box className="forms-cont">
            <Box className="message-container">
              <p className="message-description">Fields included in the form</p>
            </Box>

            <Box className="fileds-container">
              {allInputsTypes.length !== 0 ? (
                <DragDropContext onDragEnd={moveForm}>
                  <Droppable droppableId="formItems" type="FORM_ITEM">
                    {(provided) => (
                      <Box {...provided.droppableProps} ref={provided.innerRef}>
                        {allInputsTypes?.map((form, index2) => (
                          <DraggableForm
                            key={index2}
                            index={index2}
                            form={form}
                            handleDeleteField={handleDeleteField}
                            handleSelectInput={handleSelectInput}
                            moveForm={moveForm}
                            formLength={allInputsTypes.length}
                          />
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <></>
              )}
              <Typography
                style={{
                  fontSize: "11px",
                  fontWeight: "normal",
                  color: "#858585",
                  lineHeight: "1.27",
                  fontStretch: "normal",
                  fontStyle: "norma",
                  letterSpacing: "normal",
                }}
              >
                Ideal number is between 3-5
              </Typography>
            </Box>
            {allInputsTypes.length < 5 && (
              <DesignButton func={openDialog} text="Form Field"></DesignButton>
            )}

            <AddNewField
              close={closeDialog}
              open={isDialogOpen}
              allData={allData}
              setAllInputsTypes={setAllInputsTypes}
              allInputsTypes={allInputsTypes}
            ></AddNewField>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FormsModal;
