import React from "react";

import { Box, InputAdornment, InputBase, Paper } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import noBots from "../../../../assets/images/no_bots.png";

import Styles from "./ChatSearch.module.css";

const ChatSearch = ({ handleSearch, filteredData, data }) => {
  if (!data.length) {
    return (
      <Box className={Styles.searchContainer}>
        <Box className={Styles.noDataImgCont}>
          <img src={noBots} alt="No Bots Created"></img>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={Styles.searchContainer}>
      <Box className={Styles.TextHeaderDiv}>Inbound</Box>
      {data.length > 0 && data && (
        <Box className={Styles.group}>
          <Paper
            sx={{
              p: "10px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputAdornment sx={{ p: "10px 0px 10px 10px" }} position="start">
              <SearchIcon />
            </InputAdornment>

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Your Bot Here"
              inputProps={{ "aria-label": "Search Your Bot Here" }}
              onChange={(e) => {
                handleSearch(e);
              }}
            />
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default ChatSearch;
