import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Styles from "./styles/instructions.module.css";

const CollectInputUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box sx={{ display: "flex", marginTop: "64px" }} style={{width:"100%"}}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Collect Input</h1>
          <Typography className={Styles.mb20}>
            This action block allows you to collect the input or response from
            the visitors or customers
          </Typography>

          {/*<Typography className={Styles.mb20}>
            When you as a business want to ask questions to the visitor and
            expect answers in the return. You don't want to just collect any
            random input, like you're asking for the visitor's email and they
            input 'abcedfg', it wouldn't make sense to move ahead in the
            conversation without receiving a valid input.
          </Typography>*/}

          {/*<Typography>
            The collect input block has a set of in-built validations that you
            can use to verify whether the input provided is valid and only then
            move forward with the flow.
          </Typography>*/}

          <h3 id="1">When should I use this Collect Input?</h3>
          <ul className={Styles.mb40}>
            <li className={Styles.mb20}>
              Collecting information like name, email or phone
            </li>
            <li className={Styles.mb20}>Asking questions </li>
            <li className={Styles.mb20}>Collecting Feedback</li>
            {/* <li>Branching the conversation flow based on the input received</li>*/}
          </ul>

          <h3 id="2">How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up the 'Collect Input'
            action block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add the block on canvas by clicking on '<strong>+</strong>'
            </li>
            <li className={Styles.mb20}>
              Choose <strong>'Collect Input'</strong>
            </li>
            <li className={Styles.mb20}>
              Configure the following on right panel
            </li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <Typography>
                  <strong>Question: </strong>Write down the question you want to
                  ask in the rich text editor under{" "}
                  <strong>'Show this question'</strong>
                </Typography>
              </li>
             {/* <li className={Styles.mb20}>
                <Typography>
                  <strong>Variable: </strong> Select the variable in which the
                  response from the visitor will be stored
                </Typography>
              </li>*/}
            </ul>
          </ul>
          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/sendMessage`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>
                  Send Message
                </Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/buttons`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Buttons</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When Should I Use Collect Input?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When Should I Use Collect Input?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CollectInputUse;
