import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const Calendar = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Calendar</h1>
          <Typography>
            Calendar action block is used for collecting date and time from the
            or for booking appointments
          </Typography>

          <h3 id="1"> When should you use this? </h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>Collecting a date from the visitor</li>
            <li>Booking appointments </li>
          </ul>

          <h3 id="2">How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            To set up the 'Calendar' action block, you can follow the following
            steps;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add action block on canvas by clicking on <b>'+'</b>{" "}
            </li>
            <li className={Styles.mb20}>
              Choose <b>'Calendar'</b>{" "}
            </li>
            <li className={Styles.mb20}>Configure following on right panel</li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Exclude Dates: </h4>
                <Typography>
                  Select the 'Exclude Dates' tab and define any exclusion of
                  dates that you don't want to be visible in the calendar UI.
                  You can exclude dates by either;
                </Typography>
                <ul className={Styles.howToUseList}>
                  <li className={Styles.mb20}>
                    <h4>Show Message: </h4>
                    <Typography>
                      You can set the message that you would like to send to the
                      visitors along with date selection to book the appointment
                    </Typography>
                  </li>
                  <li className={Styles.mb20}>
                    Selecting the days all together like Saturdays, Sundays
                    etc.. This way all of these will not be selectable to the
                    visitor.{" "}
                  </li>
                  <li>
                    Selecting special dates like Christmas, Diwali, EID etc..{" "}
                  </li>
                  {/*<li>
                    <h4>Define Variable: </h4>
                    <Typography>
                      Define variable in which the response of the visitors will
                      be stored
                    </Typography>
                  </li>*/}
                  {/*<li>
                    <h4>Date Selection: </h4>
                    <Typography>
                      Select nature of date selection from available options .
                      You can enable 'Show Past Dates' to show the visitors past
                      dates
                    </Typography>
                  </li>*/}
                </ul>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Exclude Times: </h4>
                <Box className={Styles.mb20}>
                  <Typography className={Styles.mb10}>
                    Select the 'Exclude Times' tab and define any exclusion of
                    times.{" "}
                  </Typography>
                  <Typography className={Styles.mb10}>
                    you can also set the duration for the meeting.
                  </Typography>
                  <Typography>
                    You can exclude specific time either to a specific date.
                  </Typography>
                </Box>
                <ul className={Styles.howToUseList}>
                  <li className={Styles.mb20}>
                    <h4>Show Message: </h4>
                    <Typography>
                      You can set the message that you would like to send to the
                      visitors along with time selection to book the appointment
                    </Typography>
                  </li>
                  <li className={Styles.mb20}>
                    <h4>Duration: </h4>
                    You can select the duration of the appointment
                  </li>
                  <li className={Styles.mb20}>
                    <h4>Default timing: </h4>
                    You can set a specific time for all dates that are available
                  </li>
                  <li className={Styles.mb20}>
                    <h4>Set Specific Time To Date: </h4>
                    You can set a specific time for a specific date
                  </li>
                  <li className={Styles.mb20}>
                    <h4>Schedule Table: </h4>
                    when you click on it you will see all the times for all
                    dates that you maintain it.
                  </li>
                </ul>
              </li>
            </ul>
          </ul>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/buttons`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>Buttons</Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/sendEmail`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>
                  Send an email
                </Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When Should I Use This Block?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When Should I Use This Block?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Calendar;
