import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import powerPoint from "../../../../assets/filesTypes/powerPoint.png";
import excel from "../../../../assets/filesTypes/excel.png";
import word from "../../../../assets/filesTypes/word.png";
import pdf from "../../../../assets/filesTypes/pdf.png";
import video from "../../../../assets/filesTypes/video.png";
import file from "../../../../assets/filesTypes/file.png";
import audio from "../../../../assets/filesTypes/sound.png";
import ImageModal from "../../../../components/ui/modal/ImageModal";
import "../../Styles/chatbot.css";

const SendFileMessage = ({ path, text, fileType }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imagePath) => {
    setSelectedImage(imagePath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalIsOpen(false);
  };

  const renderFile = () => {
    if (fileType.startsWith("image")) {
      return (
        <img
          src={path}
          width={220}
          height={220}
          alt=""
          style={{
            cursor: "pointer",
          }}
        />
      );
    }
    if (fileType === "application/pdf") {
      return <img src={pdf} width={220} height={220} alt="pdf" />;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <img src={word} width={220} height={220} alt="word" />;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <img src={excel} width={220} height={220} alt="excel" />;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return (
        <img src={powerPoint} width={220} height={220} alt="power point" />
      );
    } else if (fileType.startsWith("video")) {
      return <img src={video} width={220} height={220} alt="video" />;
    } else if (fileType.startsWith("audio")) {
      return <img src={audio} width={220} height={220} alt="power point" />;
    } else {
      return <img src={file} width={220} height={220} alt="file" />;
    }
  };

  const downloadFile = (filePath, fileName) => {
    console.log(filePath);
    fetch(filePath, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
      mode: "no-cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const handleDownload = () => {
    downloadFile(path, text); // Assuming 'path' is the file path property
  };

  return (
    <>
      <Box
        onClick={
          fileType.startsWith("image") ? () => openModal(path) : handleDownload
        }
        className="message-file-container"
      >
        <Box className="file-extension">{renderFile()}</Box>
        <Box className="text-file-container">
          <Typography className="text-file" title={text}>
            {text}
          </Typography>
        </Box>
      </Box>

      {modalIsOpen && (
        <ImageModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          selectedImage={selectedImage}
        ></ImageModal>
      )}
    </>
  );
};

export default SendFileMessage;
