import React from "react";
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Styles from "./deleteButton.module.css";

const DeleteButton = ({ func, text, dis, icon }) => {
  return (
    <Button
      variant="contained"
      onClick={func}
      color="error"
      startIcon={icon ? <DeleteOutlinedIcon /> : null}
      className={Styles.deleteBtn}
      sx={{ minWidth: "80px" }}
      disabled={dis}
    >
      {text}
    </Button>
  );
};

export default DeleteButton;
