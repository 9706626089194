import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import "../../Styles/chatbot.css";

const CustomInput = ({ value, onClick, disabled }) => (
  <input
    value={value}
    onClick={onClick}
    readOnly // Make the input read-only
    disabled={disabled === true}
    style={{
      cursor: disabled ? "not-allowed" : "pointer",
      fontSize: "16px",
      padding: "10px",
      margin: "20px 0px",
      maxWidth: "100%",
    }}
  />
);

const TimeInput = ({
  timeHeader,
  allTimes,
  onInputSubmit,
  dividedTimes,
  triggerNextStep,
  SelectionDate,
}) => {
  useEffect(() => {}, [SelectionDate]);
  const [startDate, setStartDate] = useState();
  const [fromTime, setFromTime] = useState("00:00");
  const [toTime, setToTime] = useState("00:00");

  useEffect(() => {
    const selectedDateIndex = localStorage.getItem("selectedDate");
    if (selectedDateIndex !== null && allTimes[selectedDateIndex]) {
      setFromTime(allTimes[selectedDateIndex].from);
      setToTime(allTimes[selectedDateIndex].to);
    }
  }, [allTimes]);

  const handleDateSelect = (date) => {
    setStartDate(date);
    const inputTime = new Date(date);
    const formattedTime = inputTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    triggerNextStep({ value: `Selected Time: ${formattedTime}` });
    const combinedValue = {
      timeHeader: timeHeader,
      timeSelection: formattedTime,
    };
    onInputSubmit(combinedValue);
  };

  return (
    <Box className="time chatbot-container">
      <Box className="timePicker chatbot-shadow">
        <LogoContainer textHeader={timeHeader}></LogoContainer>
        <DatePicker
          selected={startDate}
          onChange={handleDateSelect}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={dividedTimes}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className="timer"
          customInput={<CustomInput disabled={startDate !== undefined} />}
          disabled={startDate !== undefined}
          minTime={new Date(`2000-01-01T${fromTime}`)}
          maxTime={new Date(`2000-01-01T${toTime}`)}
        />
      </Box>
    </Box>
  );
};

export default TimeInput;
