import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

import IOSSwitch from "../../../../../components/ui/switch/IosSwitch";

const FormDetails = ({ input, index, onBack, setAllForms, data, allData }) => {
  const [name, setName] = useState(input.value);
  const [type, setType] = useState(input.type);
  const [requierd, setRequierd] = useState(input.optional);

  const [isNameValid, setIsNameValid] = useState(false);

  // Update isNameValid based on the initial value of name
  useEffect(() => {
    const isValid = name.trim() !== "" && /[a-zA-Z]/.test(name);

    setIsNameValid(isValid);
  }, [name]);

  const handleNameChange = (e) => {
    const newNameValue = e.target.value;
    setName(newNameValue);
  };

  const handleTypeChange = (e) => {
    const newTypeValue = e.target.value;
    setType(newTypeValue);
  };

  const handleOptionalChange = (e) => {
    const newOptionalValue = e.target.checked ? true : false;
    setRequierd(newOptionalValue);
  };

  useEffect(() => {
    const updatedInputs = (data.data[data.index].forms[index] = {
      value: name,
      type: type,
      optional: requierd,
    });


    data.data[data.index].forms[index] = updatedInputs;
    data.reRender();

    // Update the state and data
    setAllForms(data.data[data.index].forms);
  }, [name, requierd, type, index]);

  return (
    <Box>
      <Box className="card-details-header-cont" onClick={onBack}>
        <Box className="Back-to-list-cont">
          <Box className="left-arrow-icon-cont">
            <KeyboardBackspaceTwoToneIcon />
          </Box>
          <Typography className="back-top-form">Back to Form</Typography>
        </Box>
        <Tooltip title="Close" placement="top">
          <Box onClick={allData.onClose} className="close-icon-cont">
            <CloseTwoToneIcon />
          </Box>
        </Tooltip>
      </Box>

      <Stack spacing={4} marginTop={"10px"}>
        <>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => handleNameChange(e)}
            inputProps={{
              maxLength: 15,
            }}
          />
          <Typography
            variant="body2"
            color={isNameValid ? "textPrimary" : "error"}
            style={{ margin: "10px 0 0 20px" }}
          >
            {isNameValid ? "Valid" : "Not Valid"}
          </Typography>
        </>

        <FormControl variant="outlined">
          <InputLabel id="field-type-label">Type</InputLabel>
          <Select
            labelId="field-type-label"
            id="demo-simple-select"
            value={type}
            onChange={(e) => handleTypeChange(e)}
            label="Type"
            autoWidth
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="tel">Phone</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="date">Date</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <p>Make this input mandatory</p>

        <IOSSwitch
          checked={requierd === true}
          onChange={(e) => handleOptionalChange(e)}
        />
      </Stack>
    </Box>
  );
};

export default FormDetails;
