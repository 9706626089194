import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaCard from "./card";
import "../../Styles/chatbot.css";
import { Box } from "@mui/material";
const SliderCards = ({ theURL }) => {
  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Box className="all-sliders">
      <Slider {...settings} className="slide">
        {theURL?.map((el, index) => (
          <MediaCard
            img={el.path && el.path}
            title={el.text}
            counting={index}
            url={el.URL}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default SliderCards;
