import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Tooltip,
} from "@mui/material";

import DeleteButton from "../../../../components/ui/button/DeleteButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Styles from "./Share.module.css";

const AddNewField = ({ close, open, chatId, accNum }) => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const baseURL = `${url.protocol}//${url.host}`;
  const textToCopy = `${baseURL}/#/viewer/${accNum}/${chatId}`;

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    close();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth={"true"}>
      <DialogTitle className={Styles.dialogTitle}>Share Bot</DialogTitle>
      <DialogContent>
        <Typography className={Styles.textHead}>
          You can share your bot with anyone by copying the link from below and
          sharing the same.
        </Typography>
        <Box className={Styles.shareCont}>
          <Box className={Styles.shareLink}>
            <Typography>{textToCopy}</Typography>
            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
              <Tooltip title={`${isCopied ? "Copied!" : "Copy To Clipboard"}`}>
                <ContentCopyIcon className={Styles.copyIcon}></ContentCopyIcon>
              </Tooltip>
            </CopyToClipboard>
          </Box>
        </Box>

        <DialogActions className="dialog-btns">
          <DeleteButton func={close} text="Close"></DeleteButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewField;
