import React, { useState, useEffect } from "react";

import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import "./styles/slider.css";

const FileUpload = ({ allData }) => {
  const [text, setText] = useState(
    allData.data[allData.index].data.description
  );

  const [isNameValid, setIsNameValid] = useState(false);
  const fileSize = [
    { size: "1" },
    { size: "2" },
    { size: "5" },
    { size: "10" },
  ];
  const [selectedSize, setSelectedSize] = useState(
    allData.data[allData.index].SelectedSize
  );
  const fileNum = [{ num: "1" }, { num: "2" }, { num: "5" }, { num: "10" }];
  const [selectedNum, setSelectedNum] = useState(
    allData.data[allData.index].SelectedNumber
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setText(newValue);
    allData.data[allData.index].data.description = newValue;
    allData.reRender();
  };

  useEffect(() => {
    const isValid = text.trim() !== "" && /[a-zA-Z]/.test(text);
    setIsNameValid(isValid);
  }, [text]);

  useEffect(() => {
    if (allData.data[allData.index].data.description.trim() === "") {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "the action block title cannot be empty",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "the action block title cannot be empty"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptyText is false
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "the action block title cannot be empty"
            )
        )
      );
    }
  }, [
    allData.data[allData.index].data.description,
    allData.data[allData.index].data.label,
  ]);

  const [checkedItems, setCheckedItems] = useState(
    allData.data[allData.index].selectedType
  );

  useEffect(() => {
    setText(allData.data[allData.index].data.description);
    setSelectedNum(allData.data[allData.index].SelectedNumber);
    setSelectedSize(allData.data[allData.index].SelectedSize);
    setCheckedItems(allData.data[allData.index].selectedType);
  }, [allData.index, allData.data]);

  useEffect(() => {
    if (checkedItems.length === 0) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "At least one file type needs to be selected",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "At least one file type needs to be selected"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "At least one file type needs to be selected"
            )
        )
      );
    }
  }, [checkedItems.length, allData.data[allData.index].data.label]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, name]);
    } else {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item !== name)
      );
    }
  };

  useEffect(() => {
    allData.data[allData.index].selectedType = checkedItems;
  }, [checkedItems]);

  useEffect(() => {
    allData.data[allData.index].SelectedSize = selectedSize;
  }, [selectedSize]);

  useEffect(() => {
    allData.data[allData.index].SelectedNumber = selectedNum;
  }, [selectedNum]);

  const fileTypes = [
    { label: ".pdf", value: ".pdf" },
    { label: ".jpg", value: ".jpg" },
    { label: ".png", value: ".png" },
    { label: ".doc", value: ".doc" },
    { label: ".docx", value: ".docx" },
    { label: ".ppt", value: ".ppt" },
    { label: ".xls", value: ".xls" },
    { label: ".xlsx", value: ".xlsx" },
    { label: ".csv", value: ".csv" },
  ];

  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Allows the user to upload files in the defined format as below"
        }
        toastHeader="File Upload"
        nodeType={allData.nodeType}
        isTextValid={!isNameValid || checkedItems.length === 0}
      ></SliderHeader>
      <Box className="slider-comp">
        <Box className="email-subject">
          <Typography>Show message</Typography>
          <TextField
            id="subject"
            multiline
            rows={3}
            inputProps={{ maxLength: 75 }}
            value={text}
            onChange={handleChange}
          ></TextField>
          <Stack flexDirection="row" justifyContent="space-around">
            <Typography
              variant="body2"
              color={isNameValid ? "#333333" : "error"}
              className="file-upload-error-message"
            >
              {isNameValid ? "Valid" : "Not Valid"}
            </Typography>
            <Typography variant="body2" className="remaining-text">
              {`${75 - text.length}`} Remaining
            </Typography>
          </Stack>
        </Box>
        <Box className="files-uploads-cont">
          <Typography>Select type of files that can be uploaded</Typography>
          <FormGroup>
            <Grid container spacing={2}>
              {fileTypes.map((fileType, index) => (
                <Grid item xs={4} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems.includes(fileType.value)}
                        onChange={handleCheckboxChange}
                        name={fileType.value}
                        defaultChecked
                      />
                    }
                    label={fileType.label}
                  />
                </Grid>
              ))}
            </Grid>
            {checkedItems.length === 0 && (
              <Typography className="error-files-uploads">
                Atleast one option needs to be selected
              </Typography>
            )}
          </FormGroup>
        </Box>
        <Box>
          <FormControl
            variant="outlined"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography>Restrict file size per file</Typography>
            <Select
              displayEmpty
              value={selectedSize}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedSize(selectedValue);
              }}
              style={{ width: "40%" }}
            >
              {fileSize.map((element, index) => (
                <MenuItem key={index} value={element.size}>
                  {`${element.size} MB`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <hr />
          <FormControl
            variant="outlined"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              marginBottom: "80px",
            }}
          >
            <Typography style={{ marginRight: "10px" }}>
              Restrict number of uploads
            </Typography>
            <Select
              displayEmpty
              value={selectedNum}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedNum(selectedValue);
              }}
              style={{ width: "40%" }}
            >
              {fileNum.map((element, index) => (
                <MenuItem key={index} value={element.num}>
                  {`${element.num}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default FileUpload;
