const routes = {
  login: "login",
  checkEmail: "checkEmail",
  otpCheckEmail: "otpCheckEmail/:userID",
  newPassword: "newPassword/:userID",
  register: "register",
  
  charts: "",
  dashboard: "design/:accNum/:chatId",
  newDashboard: "design/:accNum",
  viewer: "viewer/:accNum/:chatId",
  allChats: "allChats/:accNum",
  embeded: "embeded/:accNum/:chatId",
  templates: "templates/:accNum",

  sendMsgUse: "sendMessage",
  collectInpUse: "collectInput",
  buttonsUse: "buttons",
  calendarUse: "calendar",
  sendEmailUSe: "sendEmail",
  ImgCarouselUse: "ImgCarousel",
  SliderUse: "Slider",
  FileUploadUse: "FileUpload",
  DelayUse: "Delay",
  FormUse: "Form",
  CarouselUse: "Carousel",
};

export default routes;
