import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const SliderUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Slider</h1>
          <Typography className={Styles.mb20}>
            Action block provides interactive way to the visitors to choose a
            value from a visual slider
          </Typography>
          <h3 id="1"> When should you use it?</h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Values are to be selected from a range of finite values
            </li>
            <li className={Styles.mb20}>
              To make the conversation more interactive
            </li>
          </ul>

          <h3 id="2"> How do I set it up? </h3>

          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up the Slider action
            block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block on canvas by clicking on '+'
            </li>
            <li className={Styles.mb20}>Choose 'Slider'</li>
            <li className={Styles.mb20}>
              Double click on 'Slider' block to configure it on the right panel
            </li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Ask a question: </h4>
                <Typography>
                  Write down the question that you want to ask your visitors
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Range: </h4>
                <Typography>
                  Enter the range values (Minimum to Maximum) that you want to
                  allow the visitors to choose from
                </Typography>
              </li>
              {/*<li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Define Variables: </h4>
                <Typography>
                  You can define the variables to save the response received
                  from the visitor
                </Typography>
              </li>*/}
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Step Count: </h4>
                <Typography>
                  You can enable step count by switching on the switch. Enabling
                  this will move the slider in the increments of step count{" "}
                </Typography>
                <Box className={Styles.hint}>
                  The value for the step count can be between 2 & 5
                </Box>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Units: </h4>
                <Typography>
                  Select a unit for the slider values from the drop down list
                  i.e $, €, ₹, £, °C. By default, we do not have any unit
                  assigned to the slider{" "}
                </Typography>
              </li>
            </ul>
          </ul>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/ImgCarousel`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>
                  Image carousel
                </Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/FileUpload`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>File Upload</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should you use it?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should you use it?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SliderUse;
