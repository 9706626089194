import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

import Styles from "./styles/instructions.module.css";

const CarousalUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Carousel</h1>
          <Typography className={Styles.mb20}>
            Carousel action block is set of cards that represent something
            whether its product or service
          </Typography>

          <Typography className={Styles.mb10}>
            There are two types of Carousel blocks that Niyat Chatbot offers.
            They are:-
          </Typography>
          <ol className={Styles.howToUseListDecimal}>
            <li className={Styles.mb20}>
              <h4 className={Styles.mb20}>Image Carousel: </h4>
              <Typography>
                When you just want to display show a set of images in a card,
                not requiring any input to move forward in the conversation flow
              </Typography>
            </li>
            <li className={Styles.mb20}>
              <h4 className={Styles.mb20}>Carousel: </h4>
              <Typography>
                When you want to display details along with image along with CTA
                buttons to move forward in the conversational flow
              </Typography>
            </li>
          </ol>
          <h3 id="1">When should I use it?</h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Displaying set of products or services
            </li>
            <li className={Styles.mb20}>
              Wanting to let the visitors choose from variety of options
            </li>
            <li className={Styles.mb20}>Give an image for a button</li>
            <li className={Styles.mb20}>Showing a set of images</li>
          </ul>

          <h3 id="2">How do I set up 'Carousel' action block?</h3>
          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up 'Carousel' action
            block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block on canvas by clicking on '+'{" "}
            </li>
            <li className={Styles.mb20}>Choose 'Carousel'</li>
            <li className={Styles.mb20}>
              Double click on 'Carousel' to configure it on the right panel
            </li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Adding a card:</h4>
                <Typography>
                  Click on '+ Card' to add an card. Maximum 10 cards can be
                  added in a 'Carousel'
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Configuring the card:</h4>
                <Typography>
                  Click on 'Card' to configure the following;
                </Typography>
                <ol className={Styles.howToUseListDecimal}>
                  <li>
                    <h4 className={Styles.mb20}>Card Image:</h4>
                    <Typography>
                      Click on 'Upload an Image' to upload the image
                    </Typography>
                  </li>
                  <li>
                    <h4 className={Styles.mb20}>Card Name: </h4>
                    <Typography>
                      Provide the name of the card in the text editor
                    </Typography>
                  </li>
                  <li>
                    <h4 className={Styles.mb20}>Card Description: </h4>
                    <Typography>
                      Provide description about the card in the rich text editor
                    </Typography>
                  </li>
                </ol>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Buttons:</h4>
                <Typography>Carousel supports two type of buttons;</Typography>
                <ol className={Styles.howToUseListDecimal}>
                  <li>
                    <h4 className={Styles.mb20}>Call to Action:</h4>
                    <Typography>
                      Using this button, it will allow you to decide the next
                      set of action or flow visitor will be navigated to
                    </Typography>
                  </li>
                  <li>
                    <h4 className={Styles.mb20}>URL Button:</h4>
                    <Typography>
                      Using this button, you can configure the URL where you
                      would like to navigate the visitor to{" "}
                    </Typography>
                  </li>
                </ol>
              </li>
            </ul>
          </ul>
          <Box className={Styles.importantText}>
            Maximum 3 buttons can be added to one card.
          </Box>
          <Box className={Styles.prevNextCont}>
            <Box className={Styles.prevCont} onClick={() => navigate(`/Delay`)}>
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>Delay</Typography>
              </Box>
            </Box>
            <Box className={Styles.nextCont} onClick={() => navigate(`/Form`)}>
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Form</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should I use it?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should I use it?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip
              title="How do I set up 'Carousel' action block?"
              placement="left"
            >
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do I set up 'Carousel' action block?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CarousalUse;
