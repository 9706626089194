import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import { Box } from "@mui/material";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import "../../Styles/chatbot.css";

function DateInput({
  disabledDates,
  disabledDays,
  triggerNextStep,
  onInputSubmit,
  calenderHeader,
  userSelectedDate,
}) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to midnight

  // Custom disabled function to prevent selecting dates before today and disabled dates
  const isBeforeTodayOrDisabled = (date) => {
    if (date === null) {
      return true; // Handle null date to prevent errors
    }
    const selectedDatee = new Date(date);
    const dayOfWeek = selectedDatee.getDay(); // 0 for Sunday, 1 for Monday, and so on
    return (
      date < today ||
      disabledDates.includes(date.toISOString()) ||
      disabledDays.includes(dayOfWeek)
    );
  };

  const [selectedDate, setSelectedDate] = useState(null); // Use null instead of an empty string
  const [isDateSelected, setIsDateSelected] = useState(false);

  const handleDateSelect = (date) => {
    const inputDate = new Date(date);
    localStorage.setItem("selectedDate", inputDate.getDay());
    const formattedDate = inputDate.toLocaleDateString("en-GB");
    triggerNextStep({ value: `Selected date: ${formattedDate}` });
    const combinedValue = {
      calenderHeader: calenderHeader,
      dateSelection: formattedDate,
    };
    userSelectedDate(formattedDate);
    onInputSubmit(combinedValue);
    setSelectedDate(date);
    setIsDateSelected(true);
  };

  return (
    <Box className="calnder chatbot-container">
      <Box className="datePicker chatbot-shadow">
        <LogoContainer textHeader={calenderHeader}></LogoContainer>

        <DayPicker
          selected={selectedDate}
          onDayClick={handleDateSelect}
          className="calenedr"
          disabled={(date) => isBeforeTodayOrDisabled(date) || isDateSelected}
        />
      </Box>
    </Box>
  );
}

export default DateInput;
