import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiEnglishInput } from "react-icons/ri";
import "./NavBar.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div>
      <div
        className="head "
        style={{
          backgroundColor: "white",
          padding: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={logout} style={{ margin: "5px 5px 0 0" }}>
          logout
        </Button>
      </div>
    </div>
  );
};

export default NavBar;
