import React, { Fragment, useEffect, useRef, useState } from "react";
import { Box, Stack, TextField, Typography, Button } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import BeatLoader from "react-spinners/BeatLoader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import { styled } from "@mui/material/styles";
import CarousalButtons from "./CarousalButtons";
import CarousalCardDetailsHeader from "./CarousalCardDetailsHeader";
import { baseURL } from "../../../../axiosApi/axiosApi";

const CarouselCardDetails = ({
  allData,
  selectedCardOptions,
  optionIndx,
  handleSelectedCardOptions,
  handleCardClick,
}) => {
  const [imagePath, setImagePath] = useState("");
  const [nameCard, setNameCard] = useState("");
  const [isNameCardValid, setIsNameCardValid] = useState(false);
  useEffect(() => {
    const isValid = nameCard.trim() !== "" && /[a-zA-Z]/.test(nameCard);
    setIsNameCardValid(isValid);
  }, [nameCard]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const formats = ["bold", "italic", "underline", "list", "link"];
  const [editorContent, setEditorContent] = useState([""]);

  const handleEditorChange = (html) => {
    editorContent[0] = html;
    allData.data[allData.index].options[optionIndx].description = html;
    allData.reRender();
  };

  const handleBackToMainPage = () => {
    handleSelectedCardOptions(null);
  };

  const handleUplaodImage = (e) => {
    // taking the image data and store it in the file
    const file = e.target.files[0];

    // handle the formData to path it to the fetch
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", file.name);

    setIsLoading(true);

    fetch(`${baseURL}/file`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Parse the response as plain text
      })
      .then((data) => {
        const parts = data.split("wwwroot\\");
        const extractedString = parts[1];
        const fullPath = `${baseURL}/` + extractedString;

        setImagePath(fullPath);

        allData.data[allData.index].options[optionIndx].img = fullPath;
        allData.reRender();
        setIsLoading(false);
        fileInputRef.current.value = '';
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleNameCardChange = (e) => {
    const newNameValue = e.target.value;
    setNameCard(newNameValue);
    allData.data[allData.index].options[optionIndx].text = newNameValue;
    const cardIndex = allData.data.findIndex(
      (card) => card.id === selectedCardOptions.cardId
    );
    if (cardIndex !== -1) {
      allData.data[cardIndex].data.label = newNameValue;
    }
    allData.reRender();
  };

  useEffect(() => {
    if (selectedCardOptions) {
      setNameCard(selectedCardOptions.text);
      const updatedEditorContent = [...editorContent];
      updatedEditorContent[0] = selectedCardOptions.description;
      setEditorContent(updatedEditorContent);
      setImagePath(selectedCardOptions.img);
    }
  }, [selectedCardOptions]);

  return (
    <Fragment>
      <CarousalCardDetailsHeader
        allData={allData}
        handleBackToMainPage={handleBackToMainPage}
      />
      <Box className="card-Details-cont">
        <Box className="image-card-details-box">
          {isLoading ? (
            <BeatLoader
              className="spinner"
              color="#36d7b7"
              loading={isLoading}
              size={10}
            />
          ) : imagePath === "" ? (
            <ImageOutlinedIcon
              fontSize="large"
              style={{ height: "3em", width: "34em", color: "#333333" }}
            />
          ) : (
            <img className="card-image" src={imagePath} alt="" />
          )}
        </Box>
        <Box className="carousel-upload-image-cont">
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            className={`upload-img ${isLoading ? "disabledBtn" : ""}`}
            disabled={isLoading === true}
          >
            Upload an image
            <VisuallyHiddenInput
              type="file"
              onChange={handleUplaodImage}
              ref={fileInputRef}
              accept="image/*"
            />
          </Button>
        </Box>
        {selectedCardOptions && (
          <Fragment>
            <Stack spacing={4}>
              <>
                <TextField
                  label="Card Name"
                  variant="outlined"
                  value={nameCard}
                  onChange={(e) => handleNameCardChange(e)}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
                <Typography
                  variant="body2"
                  color={isNameCardValid ? "textPrimary" : "error"}
                  style={{ margin: "10px 0 0 20px" }}
                >
                  {isNameCardValid ? "Valid" : "Not Valid"}
                </Typography>
              </>
            </Stack>
            {editorContent.map((content, index2) => (
              <Box style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Box className="quill">
                  <ReactQuill
                    placeholder="Description"
                    theme="snow"
                    value={editorContent[0]}
                    onChange={(content) => {
                      handleEditorChange(content);
                    }}
                    modules={modules}
                    formats={formats}
                  />
                </Box>
              </Box>
            ))}
          </Fragment>
        )}
      </Box>
      <CarousalButtons
        allData={allData}
        selectedCardOptions={selectedCardOptions}
        optionIndx={optionIndx}
        handleCardClick={handleCardClick}
      />
    </Fragment>
  );
};

export default CarouselCardDetails;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
