import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Styles from "./designButton.module.css";

const DesignButton = ({ func, text, dis, isTable }) => {
  return (
    <Button
      variant="contained"
      onClick={func}
      className={Styles.designBtn}
      startIcon={!isTable && <AddIcon />}
      disabled={dis === true}
    >
      {text}
    </Button>
  );
};

export default DesignButton;
