import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const SendEmailUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box sx={{ display: "flex", marginTop: "64px" }} style={{width:"100%"}}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Send an Email</h1>
          <Typography>
            This action block of WotNot allows you to get notified over email
            when any particular action in bot is performed.
          </Typography>
          <h3 id="1">When should you use it?</h3>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb10}>When the lead is generated</li>
            <li className={Styles.mb10}>
              When the visitor has entered their details
            </li>
            <li className={Styles.mb10}>
              When a conversation has been completed
            </li>
            <li>When a conversation has reached a particular point</li>
          </ul>
          <h3 id="2">How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            To setup the 'Send Email' action block, you can follow the following
            steps;
          </h4>
          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add action block on canvas by clicking on <b>'+'</b>{" "}
            </li>
            <li className={Styles.mb20}>
              Choose <b>'Send an Email'</b>{" "}
            </li>
            <li className={Styles.mb20}>Configure following on right panel</li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4>Send an email to: </h4>
                <Typography className={Styles.mb20}>
                  Define the email IDs of the users who need to be notified via
                  email
                </Typography>
                <ul className={Styles.howToUseList}>
                  <li className={Styles.mb20}>
                    {" "}
                    <b>To:</b>{" "}
                  </li>
                  <li className={Styles.mb20}>
                    {" "}
                    <b>CC:</b>
                  </li>
                  <li className={Styles.mb20}>
                    {" "}
                    <b>BCC:</b>
                  </li>
                </ul>
              </li>
              <li className={Styles.mb20}>
                <h4>Subject: </h4>
                <Typography>Define the subject of the email </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4>Email Markup: </h4>
                <Typography>Define the content of the email</Typography>
              </li>
              <li>
                <h4>Include Chat Transcript:</h4>
                <Typography>
                  You can switch on 'Include Chat Transcript' if you would like
                  to receive entire chat transcript over the email
                </Typography>
              </li>
            </ul>
          </ul>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/calendar`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>Calendar</Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/ImgCarousel`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>
                  Image carousel
                </Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should i use this block?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should i use this block?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SendEmailUse;
