import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import axios from "axios";

import ChatsHead from "../components/chatsHead/ChatsHead";
import ChatSearch from "../components/chatSearch/ChatSearch";
import Chats from "../components/chats/Chats";

import { ToastContainer } from "react-toastify";
import HashLoader from "react-spinners/HashLoader";

import { baseURL } from "../../../axiosApi/axiosApi";

import Styles from "./AllChats.module.css";

const AllChats = () => {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const accNum = localStorage.getItem("AC");
    const fetchIdAndMessage = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/chatbot/KBot/GetChatsByAccNo`,
          {
            params: { accountno: `{"AccountNumber": ${accNum}}` },
          }
        );

        if (response.status === 200) {
          setIsLoading(false);
          const parsedData = JSON.parse(response.data[0]?.jsonData);
          // Check if parsedData is not an empty object
          if (Object.keys(parsedData).length !== 0) {
            console.log("parsedData", parsedData);
            setData(parsedData);
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchIdAndMessage();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (e) => {
    const newInput = e.target.value;
    setSearchInput(newInput);
    const searchTerm = newInput.toLowerCase();
    if (searchTerm === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((chat) =>
        chat.ChatName.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filtered);
    }
  };

  if (isLoading) {
    return (
      <Box className={Styles.loaderCont}>
        <HashLoader color={"#36D7B7"} size={"100px"} loading={isLoading} />
      </Box>
    );
  }

  return (
    <Box className={Styles.AllChatsContainer}>
      <ToastContainer />
      <Box className={Styles.chatCont}>
        <ChatsHead />
        <Box className={Styles.chatContent}>
          <ChatSearch
            handleSearch={handleSearch}
            filteredData={filteredData}
            data={data}
          />
          <Chats filteredData={filteredData} dataSet={setData} allData={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default AllChats;
