import React, { useState } from "react";
import MenuListModal from "../components/modals/MenuListModal";
import SearchModal from "../components/modals/SearchModal";
import "../styles/designMenu.css";
import { Box } from "@mui/material";
const DesignMenu = ({ btnPosition, addNode, type }) => {
  // let newPositonYoussef = {
  //   x: btnPosition.x,
  //   y: btnPosition.y,
  // };
  const types = [
    {
      type: "Send message",
      disabled: false,
    },
    {
      type: "Collect Input",
      disabled: false,
    },
    {
      type: "Buttons",
      disabled: false,
    },
    {
      type: "Carousel",
      disabled: true,
    },
    {
      type: "Calendar",
      disabled: false,
    },
    {
      type: "Send an email",
      disabled: true,
    },
    // {
    //   type: "Branch",
    //   disabled: true,
    // },
    {
      type: "Image Carousel",
      disabled: false,
    },
    {
      type: "Slider",
      disabled: false,
    },
    {
      type: "Forms",
      disabled: false,
    },
    {
      type: "File Upload",
      disabled: false,
    },
    {
      type: "Delay",
      disabled: false,
    },
    {
      type: "Flow",
      disabled: false,
    },
  ];
  const [filteredTypes, setFilteredTypes] = useState(types);
  const handleFilteredTypesChange = (newValue) => {
    setFilteredTypes(newValue);
  };

  return (
    <Box
      style={{
        position: "absolute",
        // top: `${btnPosition.y}px `,
        top: `${type === "btn" ? "73px" : "120px"}`,
        // left: `${btnPosition.x}px`,
        left: `${type === "btn" ? "-117px" : "-55px"}`,
        zIndex: "2",
        width: "310px",
        backgroundColor: "#ffffff",
        padding: "15px",
        borderRadius: "15px",
        color: "#333333",
      }}
    >
      <SearchModal
        onSearchValueChange={handleFilteredTypesChange}
        allTypes={types}
      />
      <MenuListModal
        searchResult={filteredTypes}
        addingNewNode={addNode}
      ></MenuListModal>
    </Box>
  );
};

export default DesignMenu;
