import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function NavBar({ onToggleSidebar }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <Toolbar>
          {isSmallScreen ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src={logo}
                alt="p"
                style={{
                  width: "60px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginRight: "20px",
                }}
              ></img>
              <Typography variant="h6" noWrap component="div">
                Chat Bot
              </Typography>
            </Box>
          ) : (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={onToggleSidebar}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={logo}
                alt="p"
                style={{
                  width: "70px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginRight: "20px",
                }}
              ></img>
              <Typography variant="h6" noWrap component="div">
                Chat Bot
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
