import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import mobileView from "../../assets/images/mobile_view.svg";
import Styles from "./responsivemessage.module.css";

const ResponsiveMessage = () => {
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  const [isNotResponsive, setIsNotResponsive] = useState(
    window.innerWidth <= 767 || (isLandscape && window.innerWidth < 768)
  );

  const handleResize = useCallback(() => {
    const newIsLandscape = window.matchMedia("(orientation: landscape)")
      .matches;
    setIsNotResponsive(
      window.innerWidth <= 767 || (newIsLandscape && window.innerWidth < 768)
    );
  }, []);

  useEffect(() => {
    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  if (isNotResponsive) {
    return (
      <Box className={Styles.reponsiveCont}>
        <img src={mobileView} alt="" />
        <Box className={Styles.textCont}>
          <h2>We’re not responsive, yet!</h2>
          <Typography>
            Please sign in to the customer portal from your desktop machine
          </Typography>
        </Box>
      </Box>
    );
  }

  return null; // Render nothing if the conditions are met
};

export default ResponsiveMessage;
