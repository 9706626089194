import React, { useEffect, useState } from "react";
import validator from "validator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, TextField, Typography } from "@mui/material";
import { htmlToText } from "html-to-text";

function SendAnEmail({ allData }) {
  // handle normal email
  const [send, setSend] = useState("");
  const [emailArray, setEmailArray] = useState(
    allData.data[allData.index].normalEmail
  );
  const [isSendValid, setIsSendValid] = useState(true);

  const handleSendChange = (e) => {
    const newEmail = e.target.value;
    setSend(newEmail);
    setIsSendValid(validator.isEmail(newEmail));
  };
  const handleSendEnter = (e) => {
    if (e.key === "Enter" && isSendValid) {
      setEmailArray((prevEmails) => [...prevEmails, send]);
      allData.data[allData.index].normalEmail.push(send);
      setSend("");
    }
  };
  const handleRemoveEmail = (index) => {
    setEmailArray((prevEmails) => {
      const newEmails = [...prevEmails];
      newEmails.splice(index, 1);
      allData.data[allData.index].normalEmail = newEmails;
      return newEmails;
    });
  };

  useEffect(() => {
    if (emailArray.length === 0) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "The 'TO' field cannot be empty",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'TO' field cannot be empty"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emailArray is not empty
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'TO' field cannot be empty"
            )
        )
      );
    }
  }, [emailArray, allData.data[allData.index].data.label]);

  // handle cc email
  const [cc, setCC] = useState("");
  const [ccArray, setCcArray] = useState(allData.data[allData.index].ccEmail);
  const [isCCValid, setIsCCValid] = useState(true);

  const handleCCChange = (e) => {
    const newEmail = e.target.value;
    setCC(newEmail);
    setIsCCValid(validator.isEmail(newEmail));
  };
  const handleSendEnterCc = (e) => {
    if (e.key === "Enter" && isCCValid) {
      setCcArray((prevEmails) => [...prevEmails, cc]);
      allData.data[allData.index].ccEmail.push(cc);
      setCC("");
    }
  };
  const handleRemoveEmailCc = (index) => {
    setCcArray((prevEmails) => {
      const newEmails = [...prevEmails];
      newEmails.splice(index, 1);
      allData.data[allData.index].ccEmail = newEmails;
      return newEmails;
    });
  };

  //   handle bcc
  const [Bcc, setBcc] = useState("");
  const [BccArray, setBccArray] = useState(
    allData.data[allData.index].bccEmail
  );
  const [isBccValid, setIsBccValid] = useState(true);

  const handleBccChange = (e) => {
    const newEmail = e.target.value;
    setBcc(newEmail);
    setIsBccValid(validator.isEmail(newEmail));
  };
  const handleSendEnterBcc = (e) => {
    if (e.key === "Enter" && isBccValid) {
      setBccArray((prevEmails) => [...prevEmails, Bcc]);
      allData.data[allData.index].bccEmail.push(Bcc);
      setBcc("");
    }
  };
  const handleRemoveEmailBcc = (index) => {
    setBccArray((prevEmails) => {
      const newEmails = [...prevEmails];
      newEmails.splice(index, 1);
      allData.data[allData.index].bccEmail = newEmails;
      return newEmails;
    });
  };

  // handle subject
  const [subject, setSubject] = useState(allData.data[allData.index].subject);
  const [emptySubject, setEmptySubject] = useState(false);

  const handleChangeSub = (e) => {
    const newSub = e.target.value;
    if (newSub.trim() === "") {
      setEmptySubject(true);
    } else {
      setEmptySubject(false);
    }
    setSubject(newSub);
    allData.data[allData.index].subject = newSub;
  };

  useEffect(() => {
    if (emptySubject) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "The 'Subject' field cannot be empty",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'Subject' field cannot be empty"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptySubject is not empty
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'Subject' field cannot be empty"
            )
        )
      );
    }
  }, [emptySubject, allData.data[allData.index].data.label]);

  // handle markup
  const [topic, setTopic] = useState(allData.data[allData.index].markup);
  const [emptyEditor, setEmptyEditor] = useState(false);

  const converContToText = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };

  const handleChangeTopic = (html) => {
    if (converContToText(html).trim() === "") {
      setEmptyEditor(true);
    } else {
      setEmptyEditor(false);
    }
    allData.data[allData.index].markup = html;
    setTopic(html);
    return html;
  };

  useEffect(() => {
    if (emptyEditor) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "The 'Topic' field cannot be empty",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'Topic' field cannot be empty"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptyEditor is false
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject === "The 'Topic' field cannot be empty"
            )
        )
      );
    }
  }, [emptyEditor, allData.data[allData.index].data.label]);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
    clipboard: {
      matchVisual: false, // Prevent converting spaces to new lines
    },
  };
  const formats = ["bold", "italic", "underline", "list", "link"];

  useEffect(() => {
    setEmailArray(allData.data[allData.index].normalEmail);
    setCC(allData.data[allData.index].ccEmail);
    setBcc(allData.data[allData.index].bccEmail);
    setSubject(allData.data[allData.index].subject);
    setTopic(allData.data[allData.index].markup);
  }, [allData.index, allData.data]);

  return (
    <Box
      className={"slider"}
      style={{ right: allData.isOpen ? "0" : "-350px" }}
    >
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Sends an email to notify email recipients with details collected in the bot"
        }
        toastHeader="Send An Email"
        nodeType={allData.nodeType}
        isTextValid={emailArray.length === 0 || subject.trim() === ""}
      ></SliderHeader>

      <Box className="send-email slider-comp">
        <Box className="email-input-normal">
          <label
            style={{ color: isSendValid ? "" : "red", marginBottom: "10px" }}
          >
            Send an email to
          </label>
          <TextField
            variant="outlined"
            placeholder="name@example.com"
            value={send}
            onChange={handleSendChange}
            onKeyPress={handleSendEnter}
            error={!isSendValid}
            helperText={!isSendValid && "Invalid email address"}
          />
          {emailArray.length !== 0 ? (
            <Box className="all-normal-emails">
              {emailArray.map((email, index) => (
                <Box
                  className="single-noraml-email"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    key={index}
                    style={{ display: "inline-block", margin: "5px" }}
                  >
                    {email}
                  </Box>
                  <HighlightOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveEmail(index)}
                  ></HighlightOffIcon>
                </Box>
              ))}
            </Box>
          ) : (
            ""
          )}
        </Box>

        <Box className="email-input-normal">
          <label
            style={{ color: isCCValid ? "" : "red", marginBottom: "10px" }}
          >
            CC
          </label>
          <TextField
            variant="outlined"
            placeholder="name@example.com"
            value={cc}
            onChange={handleCCChange}
            onKeyPress={handleSendEnterCc}
            error={!isCCValid}
            helperText={!isCCValid && "Invalid email address"}
          />
          {ccArray.length !== 0 ? (
            <Box className="all-normal-emails">
              {ccArray.map((email, index) => (
                <Box
                  className="single-noraml-email"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    key={index}
                    style={{ display: "inline-block", margin: "5px" }}
                  >
                    {email}
                  </Box>
                  <HighlightOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveEmailCc(index)}
                  ></HighlightOffIcon>
                </Box>
              ))}
            </Box>
          ) : (
            ""
          )}
        </Box>

        <Box className="email-input-normal">
          <label
            style={{ color: isCCValid ? "" : "red", marginBottom: "10px" }}
          >
            BCC
          </label>
          <TextField
            variant="outlined"
            placeholder="name@example.com"
            value={Bcc}
            onChange={handleBccChange}
            onKeyPress={handleSendEnterBcc}
            error={!isBccValid}
            helperText={!isBccValid && "Invalid email address"}
          />
          {BccArray.length !== 0 ? (
            <Box className="all-normal-emails">
              {BccArray.map((email, index) => (
                <Box
                  className="single-noraml-email"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    key={index}
                    style={{ display: "inline-block", margin: "5px" }}
                  >
                    {email}
                  </Box>
                  <HighlightOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveEmailBcc(index)}
                  ></HighlightOffIcon>
                </Box>
              ))}
            </Box>
          ) : (
            ""
          )}
        </Box>

        <Box className="sub-email slider-comp">
          <Typography>Subject</Typography>
          <TextField
            id="subject"
            multiline
            placeholder="Lead generated via Niyat"
            value={subject}
            onChange={handleChangeSub}
          ></TextField>
        </Box>

        <Box className="email-markup slider-comp">
          <Typography>Email markup</Typography>
          <ReactQuill
            style={{ height: "200px", marginBottom: "50px" }}
            placeholder="Add what you want to be notified when an email is sent..."
            theme="snow"
            value={topic}
            onChange={handleChangeTopic}
            modules={modules}
            formats={formats}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SendAnEmail;
