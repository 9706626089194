import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { Typography, Button, Box } from "@mui/material";
import powerPoint from "../../../../assets/filesTypes/powerPoint.png";
import excel from "../../../../assets/filesTypes/excel.png";
import word from "../../../../assets/filesTypes/word.png";
import pdf from "../../../../assets/filesTypes/pdf.png";
import file from "../../../../assets/filesTypes/file.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "../../Styles/chatbot.css";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import ImageModal from "../../../../components/ui/modal/ImageModal";
import { baseURL } from "../../../../axiosApi/axiosApi";

const FileUploadChat = ({
  maxFileSize,
  maxAllowedFiles,
  allowedExtenstions,
  fileUploadHeader,
  triggerNextStep,
  onInputSubmit,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedAcceptedFiles, setUploadedAcceptedFiles] = useState([]);
  const [filesMessage, setFilesMessage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [filesValid, setFilesValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [removeUploads, setRemoveUploades] = useState(false);
  const [allowedExtenstionsString, setAllowedExtentionsString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function formatFileSize(bytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes < kilobyte) {
      return bytes + " Bytes";
    } else if (bytes < megabyte) {
      return (bytes / kilobyte).toFixed(2) + " KB";
    } else {
      return (bytes / megabyte).toFixed(2) + " MB";
    }
  }

  const convertArrayToString = () => {
    const copyExtensions = [...allowedExtenstions];
    const extensionString = copyExtensions.join(", ");
    setAllowedExtentionsString(extensionString);
    return extensionString;
  };

  useEffect(() => {
    convertArrayToString();
  }, []);

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const getFileIcon = (fileExtension) => {
    // Map file extensions to corresponding icons
    const iconMap = {
      pdf: pdf,
      docx: word,
      pptx: powerPoint,
      doc: word,
      xls: file, // zip file
      xlsx: excel,
      csv: file,
    };

    // Default icon if extension is not recognized
    const defaultIcon = file;

    return iconMap[fileExtension] || defaultIcon;
  };

  const downloadFile = (filePath, fileName) => {
    fetch(filePath, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
      mode: "no-cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const openModal = (imagePath) => {
    setSelectedImage(imagePath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalIsOpen(false);
  };

  const handleFileUpload = (event) => {
    setIsLoading(true);
    const files = event.target.files;

    // Set the maximum allowed file size in bytes (e.g., 5 MB)
    const maximumFileSize = parseInt(maxFileSize) * 1024 * 1024;

    // Check the total number of uploaded files and the maximum allowed files
    const totalUploadedFiles = uploadedFiles.length;

    if (totalUploadedFiles + files.length > parseInt(maxAllowedFiles)) {
      setFilesValid(false);
      setErrorMessage(
        `Exceeded the maximum allowed files (${parseInt(maxAllowedFiles)}).`
      );
      return;
    }
    setFilesValid(true);

    // Iterate over each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const fileExtension = "." + getFileExtension(file.name);

      const validType = allowedExtenstions.includes(
        fileExtension.toLowerCase()
      );


      // Check the file size
      const validSize = file.size <= maximumFileSize;

      // Create FormData for each file
      const formData = new FormData();
      formData.append("formFile", file);
      formData.append("fileName", file.name);

      // Perform the upload action for each file
      fetch(`${baseURL}/file`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // Parse the response as plain text
        })
        .then((data) => {
          const parts = data.split("wwwroot\\");
          const extractedString = parts[1];
          const fullPath = `${baseURL}/` + extractedString;
          const fullImageData = {
            path: fullPath,
            allFile: file,
            valid: validSize,
            TypeValidation: validType,
          };
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            fullImageData,
          ]);
          if (validSize === true && validType === true) {
            setUploadedAcceptedFiles((prevUploadedAcceptedFiles) => [
              ...prevUploadedAcceptedFiles,
              fullImageData,
            ]);
          }
        })
        .catch((error) => {
          console.log("Error uploading file:", error);
          // Handle error as needed
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFormSubmit = (event) => {
    setRemoveUploades(true);
    event.preventDefault();
    // Handle form submission, e.g., send files to the server

    const message = uploadedAcceptedFiles.map((file, index) => {
      const fileExtension = getFileExtension(file.allFile.name); // Assuming 'name' is the file name property
      const iconSrc = getFileIcon(fileExtension);

      const handleDownload = () => {
        downloadFile(file.path, file.allFile.name); // Assuming 'path' is the file path property
      };

      return (
        <Box
          key={index}
          mb={2}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            width: "90%",
            marginLeft: "18px",
          }}
        >
          {file.allFile.type === "image/png" ||
          file.allFile.type === "image/jpeg" ? (
            <img
              src={file.path}
              width={100}
              height={100}
              alt=""
              onClick={() => openModal(file.path)}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <Box
              onClick={handleDownload}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                border: "1px solid black",
                borderRadius: "4px",
                height: "60px",
                width: "100%",
              }}
            >
              <Box
                style={{
                  margin: "0px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={iconSrc}
                  width={50}
                  height={50}
                  alt={`File Icon for ${fileExtension}`}
                />
              </Box>

              <Box
                style={{
                  flex: "2",
                  backgroundColor: "#a7abaa",
                  color: "white",
                  height: "100%",
                  paddingLeft: "15px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottomRightRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
              >
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "175px",
                  }}
                >
                  {file.allFile.name}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      );
    });

    setFilesMessage(<Box>{message}</Box>);
    onInputSubmit({
      uploadFileHeader: fileUploadHeader,
      files: uploadedAcceptedFiles,
    });
    triggerNextStep();
  };

  const handleDelete = (index) => {
    const updatedFiles = [...uploadedFiles];
    const fileToDelete = updatedFiles[index];

    // Find the index of the fileToDelete in uploadedAcceptedFiles
    const acceptedFilesIndex = uploadedAcceptedFiles.findIndex(
      (file) => file === fileToDelete
    );

    if (acceptedFilesIndex !== -1) {
      // If found, update uploadedAcceptedFiles by removing the file
      const updatedAcceptedFiles = [...uploadedAcceptedFiles];
      updatedAcceptedFiles.splice(acceptedFilesIndex, 1);
      setUploadedAcceptedFiles(updatedAcceptedFiles);
    }
    const fadeOutInterval = 50; // milliseconds
    const fadeOutDuration = 500; // milliseconds
    const steps = fadeOutDuration / fadeOutInterval;
    let currentStep = 0;

    const fadeOut = setInterval(() => {
      currentStep++;

      if (currentStep <= steps) {
        const opacity = 1 - currentStep / steps;
        updatedFiles[index] = {
          ...updatedFiles[index],
          isDeleting: true,
          opacity: opacity,
        };
        setUploadedFiles(updatedFiles);
      } else {
        clearInterval(fadeOut);

        // Remove the file from the state after the animation is complete
        const newFiles = [...updatedFiles];
        newFiles.splice(index, 1);
        setUploadedFiles(newFiles);
      }
    }, fadeOutInterval);
  };

  useEffect(() => {
    // Cleanup error message after 3 seconds
    const errorTimeout = setTimeout(() => {
      setFilesValid(true);
      setErrorMessage("");
    }, 3000);

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [errorMessage]);

  return (
    <Box className="fileUpload chatbot-container">
      <Box className="filePicker chatbot-shadow">
        <LogoContainer textHeader={fileUploadHeader}></LogoContainer>

        {uploadedFiles.length === 0 ? (
          <>
            <Box className="error-message-container">
              {!filesValid && (
                <Typography className="error-message">
                  {errorMessage}
                </Typography>
              )}
            </Box>
            <Box className="start-upload-files-cont">
              <Button
                className="upload-without-files"
                component="label"
                variant="contained"
                startIcon={
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      animation:
                        isLoading === true && uploadedFiles.length === 0
                          ? ""
                          : "upDown 2s linear infinite",
                    }}
                  >
                    {isLoading === true && uploadedFiles.length === 0 ? (
                      <BeatLoader
                        className="spinner"
                        color="#36d7b7"
                        loading={isLoading}
                        size={10}
                      />
                    ) : (
                      <CloudUploadIcon
                        style={{
                          color: "#b1b8bb",
                          fontSize: "100px",
                          margin: "0px",
                        }}
                      />
                    )}
                  </Box>
                }
              >
                <>
                  <Typography className="msg-start-upload">
                    Click here to upload your file
                  </Typography>
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileUpload}
                    multiple
                    accept={allowedExtenstionsString}
                  />
                </>
              </Button>
            </Box>
          </>
        ) : (
          <>
            {!filesValid && (
              <Box className="second-error-msg-cont">
                <Typography className="error-message">
                  {errorMessage}
                </Typography>
              </Box>
            )}

            {!removeUploads && (
              <Box className="files-container-bf-sub">
                {uploadedFiles.map((file, index) => {
                  return (
                    <Box
                      className="file-with-delete-container"
                      style={{
                        backgroundColor:
                          file.valid && file.TypeValidation
                            ? "#d9d9d96b"
                            : "#c44e47",
                        animation: file.isDeleting
                          ? "fadeOut 0.5s ease-in-out"
                          : "none",
                      }}
                    >
                      <Box className="file-texts-cont">
                        <Typography
                          className="file-name"
                          style={{
                            color:
                              file.valid && file.TypeValidation
                                ? "black"
                                : "white",
                          }}
                          title={file.allFile.name}
                        >
                          {file.allFile.name}
                        </Typography>

                        <Box className="file-size-max-cont">
                          <Typography className="file-size">
                            {formatFileSize(file.allFile.size)}
                          </Typography>
                          {!file.valid && (
                            <Typography className="max-file-size">
                              {`Maximum File Size is ${parseInt(
                                maxFileSize
                              )}MB`}
                            </Typography>
                          )}
                          {!file.TypeValidation && (
                            <Typography className="max-file-size">
                              Please upload a valid file format
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <DeleteIcon
                        className="delete-file-icon"
                        onClick={() => handleDelete(index)}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}

            {!removeUploads && (
              <Box className="buttons-cont">
                {uploadedFiles.length !== parseInt(maxAllowedFiles) && (
                  <Box className="add-file-cont">
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<AddIcon />}
                      className={`add-files-btn ${
                        removeUploads || isLoading ? "no-drop" : ""
                      }`}
                      disabled={removeUploads === true || isLoading === true}
                    >
                      Add Files
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileUpload}
                        multiple
                        accept={allowedExtenstionsString}
                      />
                    </Button>
                  </Box>
                )}

                <Box
                  className="suib-file-cont"
                  style={{
                    width:
                      uploadedFiles.length === parseInt(maxAllowedFiles)
                        ? "100%"
                        : "auto",
                  }}
                >
                  <Button
                    variant="contained"
                    className={`submit-btn ${
                      uploadedAcceptedFiles.length === 0 || isLoading
                        ? "no-drop"
                        : ""
                    }`}
                    onClick={handleFormSubmit}
                    style={{
                      width:
                        uploadedFiles.length === parseInt(maxAllowedFiles)
                          ? "90%"
                          : "auto",
                    }}
                    disabled={
                      uploadedAcceptedFiles.length === 0 || isLoading === true
                    }
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
        {filesMessage && (
          <Box mt={2} style={{ width: "100%" }}>
            {filesMessage}
            <ImageModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              selectedImage={selectedImage}
            ></ImageModal>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadChat;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
