import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import {
  Box,
  Button,
  Input,
  Popover,
  Tooltip,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";

import axios from "axios";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { baseURL } from "../../../axiosApi/axiosApi.js";
import Styles from "./navBar.module.css";

const NavBar = ({
  data,
  dataPath,
  displayErrors,
  loading,
  chatName,
  savetheChatName,
  nodeCounters,
}) => {
  console.log("testtttt", chatName);
  const [viewerLoading, setViewerLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const params = useParams();
  const chatId = localStorage.getItem("newChatIdDesign");
  console.log(params);
  const accountNumber = params.accNum;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(chatName);

  useEffect(() => {
    setText(chatName);
  }, [chatName]);

  const fetchIdAndMessage = async () => {
    const newData = data.map((item) => {
      if (item.data.type === "Send message") {
        const updatedItems = item.items.map((textItem) => {
          if (textItem.type === "text") {
            return {
              ...textItem,
              content: textItem.content.replace(/"/g, "'"),
            };
          }
          return textItem;
        });

        return {
          ...item,
          items: updatedItems,
        };
      }
      return item;
    });
    try {
      setViewerLoading(true);
      const finalObject = {
        Task: newData,
        PathTask: dataPath,
        nodeCounters: nodeCounters,
      };

      const accNum = localStorage.getItem("AC");

      const dynamicData = {
        ChatName: text,
        ChatId: chatId ? chatId : null,
        AccountNumber: `${accNum}`,
        body: finalObject,
      };

      console.log("dynamicData", dynamicData);

      const testdata = JSON.stringify(dynamicData);

      const response = await axios.get(
        `${baseURL}/chatbot/KBot/AddChatDesignNewVr`,
        {
          params: { iddata: testdata },
        }
      );

      if (response.status === 200) {
        setViewerLoading(false);
        const firstdata = JSON.stringify(response.data[0]?.jsonData);
        const secondata = JSON.parse(JSON.parse(firstdata));
        localStorage.setItem("newChatIdDesign", secondata.ChatId);
        navigate(`/viewer/${accountNumber}/${secondata.ChatId}`);
      } else {
        console.error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleTextChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 50) {
      setText(inputValue);
    }
  };

  const [lastValidInput, setLastValidInput] = useState(chatName);

  const handleBlur = () => {
    setEditing(false);
    if (text === "") {
      setText(lastValidInput);
    }
    setLastValidInput(text);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && text === "") {
      setText(lastValidInput);
    } else if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "white", zIndex: "3000" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={handleGoBack}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
          </Box>

          <Typography className={Styles.back}>Back</Typography>
        </Box>
        {editing ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Input
              autoFocus
              fullWidth
              value={text}
              onChange={handleTextChange}
              style={{ width: "25%", textAlign: "center" }}
              onKeyPress={handleKeyPress}
              onBlur={handleBlur}
            />
          </Box>
        ) : (
          <Tooltip title={text}>
            <Typography
              variant="h6"
              component="Box"
              style={{
                color: "black",
                cursor: "pointer",
              }}
              onDoubleClick={handleDoubleClick}
            >
              {text}
            </Typography>
          </Tooltip>
        )}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {displayErrors.length > 0 && (
            <Box className={Styles.errorCont}>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                {displayErrors.length === 1
                  ? "1 error"
                  : `${displayErrors.length} errors`}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                style={{ zIndex: "100000" }}
              >
                {displayErrors.map((error, index) => (
                  <Box
                    style={{
                      padding: "10px",
                      borderBottom:
                        index !== displayErrors.length - 1
                          ? "1px solid #ccc"
                          : "none",
                    }}
                  >
                    <Typography style={{ fontSize: "14px" }}>
                      {error.title}
                    </Typography>
                    <Typography style={{ fontSize: "11px", color: "#858585" }}>
                      {error.subject}
                    </Typography>
                  </Box>
                ))}
              </Popover>
            </Box>
          )}

          {loading ? (
            <Box class={Styles.savedLoading}>
              <Typography class={Styles.savedText}>Saving...</Typography>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <CheckCircleIcon
                style={{ color: "#13be66", fontSize: "21px" }}
              ></CheckCircleIcon>
              <Typography style={{ color: "#858585", fontSize: "14px" }}>
                Saved
              </Typography>
            </Box>
          )}

          <Tooltip
            title={
              displayErrors.length > 0
                ? "Please fix the errors to deploy the bot"
                : "Hitting this will open a new window where you can test the flow of your bot"
            }
          >
            <Box>
              <Button
                style={{
                  width: viewerLoading && "140.92px",
                  height: viewerLoading && "36.5px",
                }}
                variant="outlined"
                onClick={fetchIdAndMessage}
                disabled={displayErrors.length > 0}
              >
                {viewerLoading ? (
                  <BeatLoader
                    color="#36d7b7"
                    loading={viewerLoading}
                    size={7}
                  />
                ) : (
                  <Fragment>Test this bot</Fragment>
                )}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
