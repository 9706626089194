import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Styles from "./styles/instructions.module.css";

const ImgCarouselUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Image Carousel</h1>
          <Typography className={Styles.mb20}>
            Action block is used to display one or more images in a carousel to
            make it easier for the visitors to browse through
          </Typography>

          <h3 id="1"> When should you use it?</h3>
          <Typography>
            This is usually used when you just want to show a set of images in a
            card, not requiring any input to move forward for instance;
          </Typography>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Displaying set of products or services
            </li>
            <li className={Styles.mb20}>Gallery of images</li>
          </ul>

          <h3 id="2">How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up Image Carousel action
            block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block to canvas by clicking on '+'{" "}
            </li>
            <li className={Styles.mb20}>Choose 'Image Carousel'</li>
            <li className={Styles.mb20}>Configure it on the right panel</li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Upload Image: </h4>
                <Typography>
                  You can add the image / photo of your choice that you would
                  like to display it to the visitors by clicking on '
                  <b>Upload Image</b>'
                </Typography>
              </li>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Add more images: </h4>
                <Typography>
                  To add multiple images, you can click on '<b>Add Image</b>'.
                  Maximum images that you can add is 10
                </Typography>
              </li>
            </ul>
          </ul>

          <Box className={Styles.importantText}>
            <ErrorOutlineIcon className={Styles.importantIcon} />
            <Box>
              <Typography className={Styles.mb20}>
                We recommend you to upload compressed images (up-to 5 MB) in
                order to improve the load time of the images on the chat widget
              </Typography>
              <Typography>
                The file extension that we support are <b>.jpg</b>, <b>.JPEG</b>
                ,<b>.PNG</b>
              </Typography>
            </Box>
          </Box>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/sendEmail`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>
                  Send an email
                </Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/Slider`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Slider</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            <Tooltip title="When should you use it?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  When should you use it?
                </Link>
              </Box>
            </Tooltip>
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImgCarouselUse;
