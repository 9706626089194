import { Fragment, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import Share from "../share/Share";
import DeleteChart from "../deleteChart/DeleteChart";
import Styles from "./Chats.module.css";
import MenuOptions from "./MenuOptions";

const Chat = ({ chat, dataSet }) => {
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const params = useParams();

  const accountNumber = params.accNum;

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const closeShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  return (
    <Fragment>
      <Box className={Styles.card}>
        <Link
          className={Styles.leftCard}
          onClick={() => {
            localStorage.removeItem("newChatIdDesign");
          }}
          to={`/design/${accountNumber}/${chat.ChatId}`}
        >
          <Box>
            <Typography className={Styles.chatName}>{chat.ChatName}</Typography>
            {/*Next Phase*/}
            {/* <Typography style={{ color: "gray", fontSize: "12px" }}>
                    Last deployed 34m ago
                </Typography> */}
          </Box>
        </Link>

        <MenuOptions
          chat={chat}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          setIsShareDialogOpen={setIsShareDialogOpen}
        />
      </Box>

      <Share
        close={closeShareDialog}
        open={isShareDialogOpen}
        chatId={chat.ChatId}
        accNum={accountNumber}
      />

      <DeleteChart
        close={closeDeleteDialog}
        open={isDeleteDialogOpen}
        chatName={chat.ChatName}
        chatId={chat.ChatId}
        dataSet={dataSet}
      />
    </Fragment>
  );
};

export default Chat;
