import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchModal = ({ onSearchValueChange, allTypes }) => {
  const [searchValue, setSearchValue] = useState("");

  // Use allTypes as the initial value of filteredTypes
  const [filteredTypes, setFilteredTypes] = useState(allTypes);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    
    // Update filteredTypes based on the search input or use allTypes if the search is empty
    const newFilteredTypes = newValue
      ? allTypes.filter((el) =>
          el.type.toLowerCase().includes(newValue.toLowerCase())
        )
      : allTypes;

    setFilteredTypes(newFilteredTypes);
    
    // Call the callback function to update the parent's searchValue
    onSearchValueChange(newFilteredTypes);
  };
  return (
    <Box display="flex" alignItems="center" p={1}>
      <TextField
        id="input-with-icon-textfield"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search..."
        fullWidth
        value={searchValue}
        onChange={handleInputChange}
      />
    </Box>
  );
};

export default SearchModal;
