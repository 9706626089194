import React, { useState, useEffect, useRef, Fragment } from "react";
import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import DesignButton from "../../../components/ui/button/DesignButton";
import ReactQuill from "react-quill";
import BeatLoader from "react-spinners/BeatLoader";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import powerPoint from "../../../assets/filesTypes/powerPoint.png";
import excel from "../../../assets/filesTypes/excel.png";
import word from "../../../assets/filesTypes/word.png";
import pdf from "../../../assets/filesTypes/pdf.png";
import video from "../../../assets/filesTypes/video.png";
import file from "../../../assets/filesTypes/file.png";
import audio from "../../../assets/filesTypes/sound.png";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { htmlToText } from "html-to-text";
import { baseURL } from "../../../axiosApi/axiosApi";
import "./styles/slider.css";

const DraggableQuillItem = ({
  item,
  index,
  deleteItem,
  changingInEdiotContent,
  editorContLenght,
  handleChangeInput,
  renderFilee,
}) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = ["bold", "italic", "underline", "list", "link"];
  console.log("index", index);

  return (
    <Draggable draggableId={`quillItem-${index}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            opacity: snapshot.isDragging ? 0.5 : 1,
            ...provided.draggableProps.style,
            marginBottom: index === 9 ? "80px" : "20px",
          }}
        >
          <Box className="msg-desc">
            <Typography className="message-description">
              {item.type === "text" ? "Send this message" : "File"}
            </Typography>
            {(editorContLenght > 1 || item.type !== "text") && (
              <button className="delet-btn" onClick={() => deleteItem(index)}>
                <DeleteOutlineTwoToneIcon className="delete-icon"></DeleteOutlineTwoToneIcon>
              </button>
            )}
          </Box>
          {item.type === "text" ? (
            <ReactQuill
              key={index}
              placeholder="what is the ...."
              theme="snow"
              value={item.content}
              modules={modules}
              formats={formats}
              onChange={(content) => changingInEdiotContent(content, index)}
            />
          ) : (
            <ul key={index} className="img-informations">
              <li>
                {item.isLoading ? (
                  <Box className="spinner-container">
                    <BeatLoader color={"#36D7B7"} loading={item.isLoading} />
                  </Box>
                ) : (
                  <Fragment>
                    <Box className="image-container">
                      <Box>{renderFilee(item)}</Box>
                      <Box className="img-text">
                        <Typography>{item.fileData.fileName}</Typography>
                      </Box>
                    </Box>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      className="input-container"
                    >
                      <TextField
                        id="standard-basic"
                        label="Image Caption"
                        type="text"
                        variant="outlined"
                        value={item.fileData.text}
                        onChange={(e) => handleChangeInput(e, index)}
                      />
                    </Stack>
                  </Fragment>
                )}
              </li>
            </ul>
          )}
        </Box>
      )}
    </Draggable>
  );
};

const SendMessage = ({ allData }) => {
  const [counter, setCounter] = useState(
    allData.data[allData.index].imageCounter
  );
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(allData.data[allData.index].items);
    setCounter(allData.data[allData.index].imageCounter);
  }, [allData.data, allData.index]);

  useEffect(() => {
    if (allData.data && allData.data[allData.index]) {
      allData.data[allData.index].imageCounter = counter;
    }
  }, [counter]);

  const moveItem = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedEditorContent = [...items];
    const [movedItem] = updatedEditorContent.splice(result.source.index, 1);
    updatedEditorContent.splice(result.destination.index, 0, movedItem);

    setItems(updatedEditorContent);
    allData.data[allData.index].items = updatedEditorContent;
    allData.reRender();
  };

  const addTextItem = () => {
    const newTextItem = [
      ...items,
      { type: "text", content: "Hi there! My name is…" },
    ];
    setItems(newTextItem);
    allData.data[allData.index].items = newTextItem;
  };

  const deleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    allData.data[allData.index].items = updatedItems;
  };


  const handleEditorChange = (html) => {
    const text = htmlToText(html, {
      wordwrap: 130, // Adjust this to control line length
    });
    return text;
  };

  const handleEditorChangee = (content, index) => {
    const updatedEditorContent = [...items];
    updatedEditorContent[index].content = content;
    if (allData.data && allData.data[allData.index]) {
      setItems(updatedEditorContent);
      allData.data[allData.index].items = updatedEditorContent;
      allData.reRender();
    }
  };

  useEffect(() => {
    const emptyTextIndicesUpdated = items
      .map((item, idx) =>
        item.type === "text" && handleEditorChange(item.content).trim() === ""
          ? idx
          : null
      )
      .filter((idx) => idx !== null);
    const newEmptyTextErrors = emptyTextIndicesUpdated.map((index) => ({
      id: allData.data[allData.index].data.id,
      title:
        allData.data[allData.index].data.label === ""
          ? "Untitled"
          : `${allData.data[allData.index].data.label}`,
      subject: `There is no text added in this action block`,
    }));

    allData.settingErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter(
        (error) =>
          !(
            error.id === allData.data[allData.index].data.id &&
            error.subject === `There is no text added in this action block`
          )
      );

      return [...updatedErrors, ...newEmptyTextErrors];
    });
  }, [items, allData.data[allData.index].data.label]);

  useEffect(() => {
    const firstTextItem = items.find((item) => item.type === "text");

    if (firstTextItem) {
      allData.data[allData.index].data.description = firstTextItem.content;
      allData.reRender();
    }
  }, [items]);

  const textItemsLength = items.filter((item) => item.type === "text").length;
  // end texts

  // start file upload
  const fileInputRef = useRef();

  const handleUplaodImage = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", file.name);

    // Create a new file item
    const newFileItem = {
      type: "file",
      fileData: {
        path: "",
        text: `image ${counter}`,
        fileType: file.type,
        fileName: file.name,
        files: file,
      },
      isLoading: true,
    };

    // Update the state with the new file item
    setItems((prevItems) => [...prevItems, newFileItem]);
    setCounter((prevCounter) => prevCounter + 1);

    // Send the file to the server
    fetch(`${baseURL}/file`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        const parts = data.split("wwwroot\\");
        const extractedString = parts[1];
        const fullPath = `${baseURL}/` + extractedString;

        // Update the file item with the received path
        newFileItem.fileData.path = fullPath;
        newFileItem.isLoading = false;

        // Update the state with the modified file item
        setItems((prevItems) => {
          return prevItems.map((item) =>
            item === newFileItem ? { ...item } : item
          );
        });

        // Update the allData array
        allData.data[allData.index].items.push(newFileItem);
        allData.data[allData.index].imageCounter = counter;

        // Trigger re-render
        allData.reRender();
        fileInputRef.current.value = "";
      })
      .catch((error) => {
        console.log("error", error);
        newFileItem.isLoading = false;

        // Revert state changes in case of error
        setItems((prevItems) => {
          return prevItems.filter((item) => item !== newFileItem);
        });
      });
  };

  const handleInputChange = (e, index) => {
    const newInput = e.target.value;
    const updatedTextInputs = [...items];
    updatedTextInputs[index].fileData.text = newInput;
    setItems(updatedTextInputs);
    allData.data[allData.index].items[index].fileData.text = newInput;
    allData.reRender();
  };

  useEffect(() => {
    const emptyImgTextIndicesUpdated = items
      .map((item, idx) =>
        item.type === "file" && item.fileData.text === "" ? idx : null
      )
      .filter((idx) => idx !== null);

    console.log("emptyImgTextIndicesUpdated", emptyImgTextIndicesUpdated);
    const newEmptyImgTextErrors = emptyImgTextIndicesUpdated.map((index) => ({
      id: allData.data[allData.index].data.id,
      title:
        allData.data[allData.index].data.label === ""
          ? "Untitled"
          : `${allData.data[allData.index].data.label}`,
      subject: `There is no text added to the file on this action block`,
    }));

    allData.settingErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter(
        (error) =>
          !(
            error.id === allData.data[allData.index].data.id &&
            error.subject ===
              `There is no text added to the file on this action block`
          )
      );

      return [...updatedErrors, ...newEmptyImgTextErrors];
    });
  }, [items, allData.data[allData.index].data.label]);

  const renderFile = (e) => {
    const fileType = e.fileData.fileType;

    if (fileType.startsWith("image")) {
      return <img src={e.fileData.path} width={100} height={100} alt="" />;
    } else if (fileType === "application/pdf") {
      return <img src={pdf} width={100} height={100} alt="pdf" />;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return <img src={word} width={100} height={100} alt="word" />;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <img src={excel} width={100} height={100} alt="excel" />;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return (
        <img src={powerPoint} width={100} height={100} alt="power point" />
      );
    } else if (fileType.startsWith("video")) {
      return <img src={video} width={100} height={100} alt="video" />;
    } else if (fileType.startsWith("audio")) {
      return <img src={audio} width={100} height={100} alt="power point" />;
    } else {
      return <img src={file} width={100} height={100} alt="file" />;
    }
  };
  // end file upload

  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        nodeType={allData.nodeType}
        headerMessage={
          "Send message will only send messages to the user and not require a response back"
        }
        toastHeader="Send Message"
      ></SliderHeader>

      <Box className="slider-comp">
        <DragDropContext onDragEnd={moveItem}>
          <Droppable droppableId="quillItems" type="QUILL_ITEM">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index2) => (
                  <DraggableQuillItem
                    key={index2}
                    index={index2}
                    item={item}
                    deleteItem={deleteItem}
                    changingInEdiotContent={handleEditorChangee}
                    editorContLenght={textItemsLength}
                    handleChangeInput={handleInputChange}
                    renderFilee={renderFile}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        {items.length < 10 && (
          <Box className="adding-btns">
            <DesignButton func={addTextItem} text="Text"></DesignButton>
            <Box className="image-btn-container">
              <Button
                component="label"
                variant="contained"
                startIcon={<AddIcon />}
                className="upload-img"
                style={{ marginTop: "20px" }}
              >
                file
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleUplaodImage}
                  ref={fileInputRef}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SendMessage;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
