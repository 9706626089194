import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link } from "react-scroll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Styles from "./styles/instructions.module.css";

const ButtonsUse = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden",
      }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{ display: "flex", marginTop: "64px" }}
        style={{ width: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <h1 className={Styles.textHead}>Buttons</h1>
          <Typography className={Styles.mb20}>
            Buttons block is used to branch out conversation using intuitive
            buttons. You can choose any option to go down its path.
            {/*Each button can have a same path or different paths moving from it*/}
          </Typography>

          {/*<Typography>
            Button action block can be used while building the bot for Web or
            Facebook channel.
          </Typography>*/}

          {/*<h3 id="1">What are the different types of Buttons?</h3>
          <li className={Styles.mb10}>
            <b>Branch:</b> Move to a different path of the conversation flow
          </li>
          <li>
            <b>URL:</b> Open a web page and end the flow
          </li>*/}
          <Box className={Styles.importantText}>
            <ErrorOutlineIcon className={Styles.importantIcon} />
            <Box>
              {/*<Typography className={Styles.mb20}>
                Buttons will perform either of the above functions at a time
              </Typography>*/}
              <Typography>
                Maximum number of buttons that can be added is 10
              </Typography>
              {/*<Typography>
                The buttons do not disappear after they are clicked on so, the
                user can return to them at any moment during a conversation
              </Typography>*/}
            </Box>
          </Box>

          <h3 id="2">How do I set it up?</h3>

          <h4 className={Styles.followInstructions}>
            You can follow the following steps to set up the{" "}
            <strong>'Buttons'</strong> action block;
          </h4>

          <ul className={Styles.howToUseList}>
            <li className={Styles.mb20}>
              Add an action block on canvas by clicking on '<stong>+</stong>'
            </li>
            <li className={Styles.mb20}>
              Choose <strong>'Buttons'</strong>{" "}
            </li>
            <li className={Styles.mb20}>Configure it on right panel</li>
            <ul className={Styles.howToUseList}>
              <li className={Styles.mb20}>
                <h4 className={Styles.mb20}>Defining Question: </h4>
                <Typography>
                  Depending upon the preference, you can either add text message
                  or a question that needs to be sent to the visitor for
                  collecting input
                </Typography>
                <Typography>
                  Using our rich text editor, you can format the text or the
                  question that you want to send to the user. You can also add
                  emojis to make the conversation more casual and friendly
                </Typography>
              </li>
              <li>
                <h4 className={Styles.mb20}>Add Button: </h4>
                {/*<Typography>
                  You can either add Branch button or URL button based on your
                  preference. Lets have a quick look about how these buttons can
                  be configured.
                </Typography>*/}
                <ul className={Styles.howToUseList}>
                  <li>
                    {/*<h4 className={Styles.mb20}>Branch Button: </h4>*/}
                    <Typography>
                      will allow the user to navigate visitors to different flow
                      based on the selection. '+ Add Button' will allow you to
                      add multiple buttons. In-order to remove the button, you
                      can double click on the same and give the appropriate name
                      as per the preference.
                    </Typography>
                  </li>
                  {/*<li>
                    <h4 className={Styles.mb20}>URL Button: </h4>
                    <Typography className={Styles.mb20}>
                      Adding URL button will allow you to navigate your visitors
                      from one page to another either in the same tab or in
                      different tab. You can add multiple URL buttons by
                      clicking on '+ URL Button'. To configure the URL button,
                      you can follow the following steps;
                    </Typography>
                    <ul>
                      <li className={Styles.mb20}>
                        Click on the <strong>'Link Icon'</strong> displayed on
                        the left of the button
                      </li>
                      <li className={Styles.mb20}>
                        Copy / Paste the <strong>'Link / URL'</strong>, you want
                        the visitors to be navigated to
                      </li>
                      <li className={Styles.mb20}>
                        Choose whether to open in the{" "}
                        <strong>'Same tab'</strong> or in{" "}
                        <strong>'New tab'</strong>
                      </li>
                      <li className={Styles.mb20}>
                        Click on <strong>'Right'</strong> icon to configure the
                        URL
                      </li>
                    </ul>
                    <Typography>
                      Adding URL button will end the conversation flow
                    </Typography>
                  </li>*/}
                </ul>
              </li>
              {/*
              <li>
                <h4 className={Styles.mb20}>Define Variables: </h4>
                <Typography className={Styles.mb20}>
                  Store the selected button in variable. Doing this will assist
                  you to know what button the visitor has selected, which can be
                  further used while creating the lead in the CRM or sending the
                  data to any other internal systems
                </Typography>
                <Typography>
                  The input from the visitor will not be stored in variable if
                  the button type is <strong>'URL Button'</strong>
                </Typography>
              </li>*/}
            </ul>
          </ul>

          <Box className={Styles.prevNextCont}>
            <Box
              className={Styles.prevCont}
              onClick={() => navigate(`/collectInput`)}
            >
              <ArrowBackIosIcon className={Styles.prevIcon}></ArrowBackIosIcon>
              <Box className={Styles.prevTextCont}>
                <Typography className={Styles.prevTextBlock}>
                  Action Blocks - Previous
                </Typography>
                <Typography className={Styles.prevText}>
                  Collect Input
                </Typography>
              </Box>
            </Box>
            <Box
              className={Styles.nextCont}
              onClick={() => navigate(`/calendar`)}
            >
              <Box className={Styles.nextTextCont}>
                <Typography className={Styles.nextTextBlock}>
                  Next - Action Blocks
                </Typography>
                <Typography className={Styles.nextText}>Calendar</Typography>
              </Box>
              <ArrowForwardIosIcon
                className={Styles.nextIcon}
              ></ArrowForwardIosIcon>
            </Box>
          </Box>
        </Box>
        <Box
          className={Styles.asideLinks}
          sx={{
            flexGrow: 1,
            p: 3,
            width: "57%",
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Box className={Styles.allLinksCont}>
            <Box className={Styles.mb10}>
              <Typography className={Styles.LinksTitle}>
                ON THIS PAGE
              </Typography>
            </Box>
            {/*<Tooltip
              title="What Are The Different Types Of Buttons?"
              placement="left"
            >
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 1 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="1"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(1)}
                >
                  What Are The Different Types Of Buttons?
                </Link>
              </Box>
            </Tooltip>*/}
            <Tooltip title="How do i set it up?" placement="left">
              <Box
                className={`${Styles.pointerNavCont} ${
                  activeLink === 2 ? Styles.linkActive : ""
                }`}
                style={{ padding: "0px 24px", margin: "3px 0px" }}
              >
                <Link
                  to="2"
                  smooth={true}
                  duration={500}
                  className={Styles.pointerNav}
                  offset={-85}
                  onClick={() => handleLinkClick(2)}
                >
                  How do i set it up?
                </Link>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ButtonsUse;
