import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";
import routes from "../routes";

const ProtectedDesign = () => {
  const localData = localStorage.getItem("AC");
  return (
    <Fragment>
      {localData && localData !== "" ? (
        <Outlet></Outlet>
      ) : (
        <Navigate to={routes.login} />
      )}
    </Fragment>
  );
};

export default ProtectedDesign;
