import React, { useEffect, useState } from "react";
import {
  Divider,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
} from "@mui/material";
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import ViewDayTwoToneIcon from "@mui/icons-material/ViewDayTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import RouteTwoToneIcon from "@mui/icons-material/RouteTwoTone";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import CandlestickChartTwoToneIcon from "@mui/icons-material/CandlestickChartTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import UpdateIcon from "@mui/icons-material/Update";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

const MenuListModal = ({ searchResult, addingNewNode }) => {
  const [listHeight, setListHeight] = useState("100%");
  const [listOverflow, setListOverflow] = useState("hidden");

  useEffect(() => {
    // Calculate the list height dynamically based on the number of items
    const itemHeight = 48; // Adjust this value as needed
    const maxItemsToShow = 5; // Set the maximum number of items to display without scrolling
    const numItems = searchResult.length;

    if (numItems <= maxItemsToShow) {
      // If the number of items is less than or equal to the maximum, set height to auto
      setListHeight("auto");
      setListOverflow("hidden");
    } else {
      // If there are more items, set a maximum height and enable scrolling
      setListHeight(`${itemHeight * maxItemsToShow}px`);
      setListOverflow("scroll");
    }
  }, [searchResult]);

  function getIconForType(type) {
    switch (type) {
      case "Send message":
        return <ChatTwoToneIcon style={{ color: "#34b8ca" }} />;
      case "Collect Input":
        return <CandlestickChartTwoToneIcon style={{ color: "#359cec" }} />;
      case "Buttons":
        return <RadioButtonCheckedTwoToneIcon style={{ color: "#f5be4f" }} />;
      case "Carousel":
        return <ViewDayTwoToneIcon style={{ color: "#f5be4f" }} />;
      case "Calendar":
        return <CalendarMonthTwoToneIcon style={{ color: "#359cec" }} />;
      case "Send an email":
        return <EmailTwoToneIcon style={{ color: "#cb62e4" }} />;
      case "Branch":
        return <RouteTwoToneIcon style={{ color: "#f5be4f" }} />;
      case "Image Carousel":
        return <ViewCarouselTwoToneIcon style={{ color: "#2ab9cd" }} />;
      case "Slider":
        return <TuneTwoToneIcon style={{ color: "#359cec" }} />;
      case "Forms":
        return <FormatListBulletedTwoToneIcon style={{ color: "#359cec" }} />;
      case "File Upload":
        return <InsertDriveFileTwoToneIcon style={{ color: "#339af0" }} />;
      case "Delay":
        return <UpdateIcon style={{ color: "#2ab9cd" }} />;
      case "Flow":
        return <AccountTreeIcon style={{ color: "#2ab9cd" }} />;
      default:
        return null;
    }
  }
  return (
    <List
      className="list-container"
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
      style={{
        overflowY: listOverflow,
        overflowX: "hidden",
        height: listHeight,
      }}
    >
      {searchResult.map((el, index) => (
        <div style={{ padding: "0px" }}>
          <ListItem
            button
            disabled={el.disabled}
            onClick={() => addingNewNode(el.type)}
          >
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: "transparent" }}>
                {getIconForType(el.type)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={el.type} />
          </ListItem>
          {index !== searchResult.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  );
};

export default MenuListModal;
