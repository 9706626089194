import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import DeleteButton from "../../../components/ui/button/DeleteButton";

import Styles from "./backModal.module.css";
import DesignButton from "../../../components/ui/button/DesignButton";

const BackModal = ({ close, open, handleSave, handleDiscard }) => {
  return (
    <Dialog
      style={{ zIndex: 10000 }}
      open={open}
      onClose={close}
      maxWidth="xs"
      fullWidth={"true"}
    >
      <DialogContent>
        <Typography className={Styles.textHead}>
          Do you want to leave the page?
        </Typography>
        <DialogActions className="dialog-btnss">
          <DeleteButton func={handleDiscard} text="Descard"></DeleteButton>
          <DesignButton
            func={handleSave}
            text="Save"
            isTable={true}
          ></DesignButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default BackModal;
