import React, { useEffect, useState } from "react";
import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { Box, Typography } from "@mui/material";

import "./styles/slider.css";

const Delay = ({ allData }) => {
  const [alignment, setAlignment] = useState(allData.data[allData.index].delay);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    allData.data[allData.index].delay = newAlignment;
  };

  useEffect(() => {
    setAlignment(allData.data[allData.index].delay);
  }, [allData.index, allData.data])
  
  return (
    <Box className="slider" style={{ right: allData.isOpen ? "0" : "-350px" }}>
      <SliderHeader
        closeData={allData}
        nodeType={allData.nodeType}
        headerMessage={
          "Adds an extra dealy on top of what the system current adds between each block"
        }
        toastHeader="Delay"
      ></SliderHeader>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ marginBottom: "5px" }}>Delay (Second)</Typography>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton style={{ fontWeight: "800" }} value={1}>
            1s
          </ToggleButton>
          <ToggleButton style={{ fontWeight: "800" }} value={2}>
            2s
          </ToggleButton>
          <ToggleButton style={{ fontWeight: "800" }} value={5}>
            5s
          </ToggleButton>
          <ToggleButton style={{ fontWeight: "800" }} value={10}>
            10s
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default Delay;
