import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { baseURL } from "../../../../../axiosApi/axiosApi";
import { useNavigate } from "react-router-dom";
import Niyat_logo from "../../../../../assets/images/logo.jpg";

import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import Styles from "../styles/checkEmail.module.css";

const CheckEmail = () => {
  const [viewerLoading, setViewerLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();
  const [email, setEmail] = useState();
  const [errorDiv, setErrorDiv] = useState(false);
  const navigate = useNavigate();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };
  const handleEmailChange = async (e, type) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    setValue(type, newEmail);
    await trigger(type);
  };

  const navigatehandler = async (e) => {
    setViewerLoading(true);
    try {
      const response = await axios.put(
        `${baseURL}/chatbot/KBot/MailForgetPassword?email=${email}`
      );
      if (response.status === 200) {
        const { data } = response;
        navigate(`/otpCheckEmail/${parseInt(data)}`);
        setViewerLoading(false);
        localStorage.setItem("EmailForNewPass", email);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorDiv(true);
        setViewerLoading(false);
      }
    }
  };
  return (
    <Box className={Styles.mainCheckEmailCont}>
      <Box className={Styles.formCont}>
        <img src={Niyat_logo} alt="niyat-logo"></img>
        <Box className={Styles.words}>
          <Typography className={Styles.title}>Find Your Account</Typography>
          <Typography className={Styles.someTools}>
            Please enter your email address to search for your account.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(navigatehandler)}>
          <FormControl isInvalid={errors.email}>
            <Typography>Email</Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "This Field Is Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Wrong Email Pattern",
                },
                validate: {
                  noOnlySpaces: (value) =>
                    !isOnlySpaces(value) || "It Mustn't contains only spaces",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => {
                    handleEmailChange(e, "email");
                  }}
                  type="email"
                />
              )}
            />

            <Typography className={Styles.errorValidation}>
              {errors.email && errors.email.message}
            </Typography>
          </FormControl>

          <Button variant="contained" type="submit" className={Styles.Submit}>
            {viewerLoading ? (
              <BeatLoader color="black" loading={viewerLoading} size={7} />
            ) : (
              <Fragment>Submit</Fragment>
            )}
          </Button>
        </form>
        {errorDiv && (
          <Box style={{ color: "red", marginTop: "10px" }}>
            This Email Isn't Found
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckEmail;
