import React, { Fragment, useEffect, useState } from "react";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import AdsClickTwoToneIcon from "@mui/icons-material/AdsClickTwoTone";
import ViewDayTwoToneIcon from "@mui/icons-material/ViewDayTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import RouteTwoToneIcon from "@mui/icons-material/RouteTwoTone";
import CandlestickChartTwoToneIcon from "@mui/icons-material/CandlestickChartTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import Styles from "./sliderHeader.module.css";

const SliderHeader = ({
  closeData,
  headerMessage,
  toastHeader,
  isURLValid,
  isTextValid,
  nodeType,
  isTextArrValid,
}) => {
  const [isSendValid, setIsSendValid] = useState(true);

  const handleRemoveButtonClick = () => {
    // Check if remove function exists and is a function
    if (closeData.remove && typeof closeData.remove === "function") {
      // Call the remove function with the node ID

      closeData.settingErrors((prevErrors) => {
        return prevErrors.filter(
          (error) => error.id !== closeData.data[closeData.index].data.id
        );
      });
      closeData.remove(closeData.data[closeData.index].id);
      closeData.reRender();
      closeData.onClose();
    } else {
      // Handle the case where the remove function is not defined or not a function
      console.error("Remove function is not defined or not a function");
    }
  };

  const [text, setText] = useState(closeData.data[closeData.index].data.label);

  useEffect(() => {
    setText(closeData.data[closeData.index].data.label);
  }, [closeData.data, closeData.index]);

  const getIcon = (nodeType) => {
    switch (nodeType) {
      case "Send message":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e4fafb",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ChatTwoToneIcon style={{ color: "#34b8ca", fontSize: "30px" }} />
          </Box>
        );
      case "Buttons":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#fff6d9",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RadioButtonCheckedTwoToneIcon
              style={{ color: "#f5be4f", fontSize: "30px" }}
            />
          </Box>
        );
      case "Forms":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormatListBulletedTwoToneIcon
              style={{ color: "#359cec", fontSize: "30px" }}
            />
          </Box>
        );
      case "Calendar":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CalendarMonthTwoToneIcon
              style={{ color: "#359cec", fontSize: "30px" }}
            />
          </Box>
        );
      case "Image Carousel":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e4fafb",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ViewCarouselTwoToneIcon
              style={{ color: "#2ab9cd", fontSize: "30px" }}
            />
          </Box>
        );
      case "Collect Input":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CandlestickChartTwoToneIcon
              style={{ color: "#359cec", fontSize: "30px" }}
            />
          </Box>
        );
      case "Carousel":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e4fafb",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ViewDayTwoToneIcon
              style={{ color: "#f5be4f", fontSize: "30px" }}
            />
          </Box>
        );
      case "Send an email":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#f8f0fc",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailTwoToneIcon style={{ color: "#cb62e4", fontSize: "30px" }} />
          </Box>
        );
      case "Branch":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#fff6d9",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RouteTwoToneIcon style={{ color: "#f5be4f", fontSize: "30px" }} />
          </Box>
        );
      case "Slider":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TuneTwoToneIcon style={{ color: "#359cec", fontSize: "30px" }} />
          </Box>
        );
      case "File Upload":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InsertDriveFileTwoToneIcon
              style={{ color: "#359cec", fontSize: "30px" }}
            />
          </Box>
        );
      case "Delay":
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#e7f5fe",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UpdateIcon style={{ color: "#2ab9cd", fontSize: "30px" }} />
          </Box>
        );
      default:
        return (
          <Box
            style={{
              padding: "10px",
              backgroundColor: "#fff0f5",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AdsClickTwoToneIcon
              style={{ color: " #ed2b2b", fontSize: "30px" }}
            />
          </Box>
        );
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setIsSendValid(false);
      const errorObject = {
        id: closeData.data[closeData.index].data.id,
        title: "Untitled",
        subject: `The ${nodeType} header cannot be empty`,
      };
      closeData.settingErrors((prevErrors) => {
        return [...prevErrors, errorObject];
      });
    } else {
      setIsSendValid(true);
      closeData.settingErrors((prevErrors) => {
        return prevErrors.filter(
          (error) =>
            !(
              error.id === closeData.data[closeData.index].data.id &&
              error.title === "Untitled"
            )
        );
      });
    }
    setText(newValue);
    closeData.data[closeData.index].data.label = newValue;
    closeData.reRender();
  };

  const handleNavigate = (type) => {
    const baseUrl = `${window.location.origin}/#`;
    if (type === "Send message") {
      const newTabUrl = `${baseUrl}/sendMessage`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Collect Input") {
      const newTabUrl = `${baseUrl}/collectInput`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Buttons") {
      const newTabUrl = `${baseUrl}/buttons`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Calendar") {
      const newTabUrl = `${baseUrl}/calendar`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Send an email") {
      const newTabUrl = `${baseUrl}/sendEmail`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Forms") {
      const newTabUrl = `${baseUrl}/Form`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Image Carousel") {
      const newTabUrl = `${baseUrl}/ImgCarousel`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Slider") {
      const newTabUrl = `${baseUrl}/Slider`;
      window.open(newTabUrl, "_blank");
    } else if (type === "File Upload") {
      const newTabUrl = `${baseUrl}/FileUpload`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Delay") {
      const newTabUrl = `${baseUrl}/Delay`;
      window.open(newTabUrl, "_blank");
    } else if (type === "Carousel") {
      const newTabUrl = `${baseUrl}/Carousel`;
      window.open(newTabUrl, "_blank");
    }
  };
  return (
    <Fragment>
      <Box className={Styles.sliderHeader}>
        <Box className={Styles.sliderNav}>
          <Box>{getIcon(nodeType)}</Box>
          <Box className={Styles.instructionsPageCont}>
            <Typography
              className={Styles.instructionPage}
              onClick={() => handleNavigate(nodeType)}
            >
              How to use
            </Typography>
          </Box>
          <Box className={Styles.deleteCloseCont}>
            <Tooltip title="Delete" placement="top">
              <Box
                className={Styles.deleteCont}
                onClick={handleRemoveButtonClick}
              >
                <DeleteOutlineIcon className={Styles.deleteIcon} />
              </Box>
            </Tooltip>

            <Tooltip title="Close" placement="top">
              <Box className={Styles.closeCont} onClick={closeData.onClose}>
                <CloseTwoToneIcon className={Styles.closeIcon} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box className={Styles.sliderCompBtnn}>
        <Box className={Styles.messageBox}>
          <TextField
            variant="standard"
            className={Styles.message}
            value={text}
            onChange={handleChange}
            error={!isSendValid}
            helperText={!isSendValid && "This field cannot be empty"}
            inputProps={{ maxLength: 86 }}
          />

          <Typography className={Styles.messageDesc}>
            {headerMessage}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default SliderHeader;
