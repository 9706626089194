import React from "react";
import NavBar from "../components/NavBar/NavBar";
import DonutChart from "../components/Charts/DonutChart ";
import ColumnChart from "../components/Charts/ColumnChart ";
import Box from "@mui/system/Box";
import Grid from "@mui/system/Unstable_Grid";

const ChatsPage = () => {
  const testData = [
    {
      id: 1,
      number: "5",
      text: "number of Chats",
      color: "red",
    },
    {
      id: 2,
      number: "12",
      text: "number of User",
      color: "green",
    },
    {
      id: 3,
      number: "90",
      text: "Average Conversation Duration ",
      color: "orange",
    },
  ];

  return (
    <div className="page d-flex">
      <div className="content w-full">
        <NavBar></NavBar>

        <div
          className="tickets-compo"
          style={{ display: "flex", margin: "20px", gap: "20px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
              >
                {testData.map((el) => {
                  return (
                    <Grid xs={2} sm={4}>
                      <div
                        key={el.id}
                        style={{
                          backgroundColor: el.color,
                          padding: "15px",
                          borderRadius: "6px",
                        }}
                      >
                        <p style={{ color: "white", fontSize: "30px" }}>
                          {el.number}
                        </p>
                        <p style={{ color: "white", fontSize: "18px" }}>
                          {el.text}
                        </p>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 4, md: 3 }}>
                <Grid xs={12} md={6}>
                  <h1>chats</h1>
                  {/* xs={12} will make it take full width on small screens, md={6} will make it take half width on medium screens */}
                  <DonutChart />
                </Grid>

                <Grid xs={12} md={6}>
                  <h1
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    User decisions
                  </h1>
                  <ColumnChart />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatsPage;
