import { Box } from "@mui/material";

import FilterLabel from "./FilterLabel";
import Chat from "./Chat";
import Styles from "./Chats.module.css";

// Next Phase
// import IOSSwitch from "../../../../components/ui/switch/IosSwitch";

const Chats = ({ filteredData, allData, dataSet }) => {
  return (
    <Box className={Styles.lowerChatCont}>
      {allData?.length > 0 && (
        <FilterLabel>
          {filteredData?.length > 0
            ? `${filteredData?.length} bots created`
            : "No bots found"}
        </FilterLabel>
      )}

      {filteredData?.length > 0 &&
        filteredData?.map((chat) => {
          return <Chat chat={chat} key={chat.ChatId} dataSet={dataSet} />;
        })}
    </Box>
  );
};

export default Chats;
