import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { DayPicker } from "react-day-picker";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";

import "../../styles/slider.css";

const ExcludeDates = ({ allData, daysOfWeek, setExcludedDaysAndTimes }) => {
  const [selectedDates, setSelectedDates] = useState(
    allData.data[allData.index].disabledDates
  );
  const [excludedDaysOfWeek, setExcludedDaysOfWeek] = useState(
    allData.data[allData.index].excludedDaysOfWeek
  );
  const [dateText, setDateText] = useState(
    allData.data[allData.index].data.description
  );

  const [isNameValid, setIsNameValid] = useState(false);
  const [emptyDateText, setEmptyDateText] = useState(false);

  useEffect(() => {
    if (emptyDateText) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "There is no text added in this action block (exclude date)",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                "There is no text added in this action block (exclude date)"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptyDateText is false
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                "There is no text added in this action block (exclude date)"
            )
        )
      );
    }
  }, [emptyDateText, allData.data[allData.index].data.label]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setEmptyDateText(true);
    } else {
      setEmptyDateText(false);
    }
    setDateText(newValue);
    allData.data[allData.index].data.description = newValue;
    allData.reRender();
  };
  useEffect(() => {
    const isValid = dateText.trim() !== "" && /[a-zA-Z]/.test(dateText);

    setIsNameValid(isValid);
  }, [dateText]);

  // styling the select material ui
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function formatDateToYYYYMMDD(date) {
    // Convert the date to a Date object
    const dateObject = new Date(date);

    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(dateObject.getDate()).padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    return `${year}-${month}-${day}`;
  }

  const [dateDrop, setDateDrop] = useState(
    allData.data[allData.index].data.calenderOpining
  );

  const handleDateDrob = () => {
    const lastValue = dateDrop;
    setDateDrop(!lastValue);
    allData.data[allData.index].data.calenderOpining = !lastValue;
  };

  const [selectedDays, setSelectedDays] = useState(
    allData.data[allData.index].disabledDays
  );

  useEffect(() => {
    setSelectedDates(allData.data[allData.index].disabledDates);
    setExcludedDaysOfWeek(allData.data[allData.index].excludedDaysOfWeek);
    setDateText(allData.data[allData.index].data.description);
    setSelectedDays(allData.data[allData.index].disabledDays);
    setDateDrop(allData.data[allData.index].data.calenderOpining);
  }, [allData.data, allData.index]);

  const handleSelectedDaysChange = (event) => {
    const selected = event.target.value;
    setSelectedDays(selected);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set the time to midnight

  // Custom disabled function to prevent selecting dates before today
  const isBeforeToday = (date) => date < today;

  const handleDateSelect = (date) => {
    if (isBeforeToday(date) || excludedDaysOfWeek.includes(date.getDay())) {
      // Date is before today or is one of the excluded days, prevent selection
      return;
    }

    // Toggle selection of date
    const index = selectedDates.findIndex((selectedDate) => {
      const selectedDateObject = new Date(selectedDate);
      const currentDateObject = new Date(date);
      return selectedDateObject.getTime() === currentDateObject.getTime();
    });

    if (index === -1) {
      // Date is not in the selectedDates array, add it
      setSelectedDates([...selectedDates, date]);
      allData.data[allData.index].disabledDates.push(date);
      allData.reRender();
    } else {
      // Date is already in the selectedDates array, remove it
      const updatedDates = selectedDates.filter(
        (selectedDate, i) => i !== index
      );
      setSelectedDates(updatedDates);
      allData.data[allData.index].disabledDates = updatedDates;
      allData.reRender();
    }
  };

  function handleDateDelete(date) {
    const updatedDates = selectedDates.filter(
      (selectedDate) => selectedDate !== date
    );
    setSelectedDates(updatedDates);
    allData.data[allData.index].disabledDates = updatedDates;
    allData.reRender();
  }

  const handleExcludedDayChange = (day, checked) => {
    if (checked) {
      // Include the day in excludedDaysOfWeek
      const allExcludedDaysOfWeek = [...excludedDaysOfWeek, day];
      setExcludedDaysOfWeek(allExcludedDaysOfWeek);
      allData.data[allData.index].excludedDaysOfWeek = allExcludedDaysOfWeek;
      allData.data[allData.index].disabledDays.push(day);
      // Exclude the day from ExcludedDaysAndTimes
      setExcludedDaysAndTimes((prev) => {
        const { [day]: omit, ...rest } = prev;
        allData.data[allData.index].timing = rest;
        return rest;
      });
    } else {
      // Remove the day from excludedDaysOfWeek
      const updatedExcludedDaysOfWeek = excludedDaysOfWeek.filter(
        (excludedDay) => excludedDay !== day
      );
      setExcludedDaysOfWeek(updatedExcludedDaysOfWeek);
      allData.data[
        allData.index
      ].excludedDaysOfWeek = updatedExcludedDaysOfWeek;
      const dayIndex = allData.data[allData.index].disabledDays.indexOf(day);
      if (dayIndex !== -1) {
        allData.data[allData.index].disabledDays.splice(dayIndex, 1);
      }
      // Add the day back to excludedDaysAndTimes with default values
      setExcludedDaysAndTimes((prev) => ({
        ...prev,
        [day]: {
          from: allData.data[allData.index].defaultFrom,
          to: allData.data[allData.index].defaultTo,
        },
      }));
      const updatedTiming = { ...allData.data[allData.index].timing };
      updatedTiming[day] = {
        from: allData.data[allData.index].defaultFrom,
        to: allData.data[allData.index].defaultTo,
      };
      allData.data[allData.index].timing = updatedTiming;
    }
    allData.reRender();
  };

  return (
    <Box className="slider-comp">
      <Box className="email-subject">
        <Typography>Show message</Typography>
        <TextField
          id="subject"
          multiline
          rows={3}
          inputProps={{ maxLength: 75 }}
          value={dateText}
          onChange={handleChange}
        ></TextField>
        <Stack flexDirection="row" justifyContent="space-around">
          <Typography
            variant="body2"
            color={isNameValid ? "#333333" : "error"}
            className="date-error-msg"
          >
            {isNameValid ? "Valid" : "Not Valid"}
          </Typography>
          <Typography variant="body2" className="remaining-text">
            {`${75 - dateText.length}`} Remaining
          </Typography>
        </Stack>
      </Box>

      <Box className="drop-down slider-comp">
        <FormControl variant="outlined">
          <Typography>Exclude All-Days</Typography>
          <Select
            displayEmpty
            multiple
            value={selectedDays}
            onChange={handleSelectedDaysChange}
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => {
              // Filter out the excluded value (e.g., 7)
              const filteredSelected = selected.filter((value) => value !== 7);

              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {filteredSelected.map((value) => (
                    <Chip key={value} label={`${daysOfWeek[value]}`} />
                  ))}
                </Box>
              );
            }}
            className="exclude-all-days-select"
            MenuProps={MenuProps}
          >
            <MenuItem value="" disabled>
              <em>Select Days</em> {/* Placeholder */}
            </MenuItem>
            {daysOfWeek?.map((day, index) => (
              <MenuItem
                key={index}
                value={index}
                onClick={() =>
                  handleExcludedDayChange(
                    index,
                    !excludedDaysOfWeek.includes(index)
                  )
                }
              >
                <Box>
                  <Checkbox checked={excludedDaysOfWeek.includes(index)} />
                  {day}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box className="exclude-specific-dates">
        <Typography>Exclude specific dates</Typography>
        <Box className="dates-container">
          <Box className="fa-calendar" onClick={handleDateDrob}>
            <CalendarMonthTwoToneIcon />
          </Box>
          <Box className="exclude-date-box">
            {selectedDates.map(
              (date, index) =>
                date !==
                  "Tue Jan 23 2021 00:00:00 GMT-0800 (Pacific Standard Time)" && (
                  <Box className="all-excluded-days" key={index}>
                    {formatDateToYYYYMMDD(date)}
                    <HighlightOffOutlinedIcon
                      className="delete-excluded-day"
                      onClick={() => handleDateDelete(date)}
                    />
                  </Box>
                )
            )}
          </Box>
        </Box>
        {!dateDrop ? (
          <Typography className="calendar-info">
            Click on the calendar icon to select a specific date
          </Typography>
        ) : (
          ""
        )}
      </Box>
      {dateDrop ? (
        <DayPicker
          mode="multiple"
          selected={selectedDates.map((dateString) => new Date(dateString))}
          onDayClick={handleDateSelect}
          disabled={(date) => {
            return (
              isBeforeToday(date) || excludedDaysOfWeek.includes(date.getDay())
            );
          }}
          style={{ backgroundColor: "#f6fafd", marginBottom: "120px" }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ExcludeDates;
