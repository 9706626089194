import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { Box, Button } from "@mui/material";
import LogoContainer from "../../../../components/ui/logoContainer/LogoContainer";
import "../../Styles/chatbot.css";

const SliderOption = ({
  from,
  to,
  countStep,
  enableCount,
  unitSlider,
  triggerNextStep,
  onInputSubmit,
  optionHeader,
}) => {
  const [value, setValue] = useState(from);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const step = enableCount ? parseInt(countStep) : 1; // Set step based on enableCount

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the user inputs back to the parent component
    const combinedValue = {
      sliderHeader:  optionHeader ,
      sliderValue: `${value}${unitSlider}`,
    };
    onInputSubmit(combinedValue);
    triggerNextStep({ value: `Selected range: ${value}${unitSlider}` });
    setFormSubmitted(true);
  };

  return (
    <Box className="options chatbot-container">
      <Box className="optionPicker chatbot-shadow">
        <LogoContainer textHeader={optionHeader}></LogoContainer>
        <form onSubmit={handleSubmit} className="option-form">
          <Slider
            aria-label="Default"
            valueLabelDisplay="on"
            value={value}
            valueLabelFormat={(value) => `${value}${unitSlider}`}
            onChange={handleChange}
            min={parseInt(from)}
            max={parseInt(to)}
            step={step}
            disabled={formSubmitted}
          />
          <Box className="slider-option-submit-button">
            <Button type="submit" disabled={formSubmitted}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SliderOption;
