import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import "../../Styles/chatbot.css";

export default function MediaCard({ img, title, counting, url }) {
  return (
    <Card sx={{ maxWidth: 300 }} className="my-card">
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: "#d9d9d9",
              color: "#333333",
              fontSize: "25px",
              fontWeight: "bold",
            }}
            aria-label="recipe"
          >
            {counting + 1}
          </Avatar>
        }
        style={{ backgroundColor: "#f9f2f2" }}
        title={title}
        titleTypographyProps={{
          style: {
            fontSize: "1.2rem",
            fontWeight: "bold",
          },
        }}
      />
      {url !== "" ? (
        <a href={url} target="_blank" title={url} rel="noreferrer">
          <CardMedia
            sx={{ objectFit: "cover", height: 0, paddingTop: "90%" }}
            image={img}
          />
        </a>
      ) : (
        <CardMedia
          sx={{ objectFit: "cover", height: 0, paddingTop: "90%" }}
          image={img}
        />
      )}
    </Card>
  );
}
