import React, { Fragment, memo, useEffect, useState } from "react";
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import AdsClickTwoToneIcon from "@mui/icons-material/AdsClickTwoTone";
import ViewDayTwoToneIcon from "@mui/icons-material/ViewDayTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import RouteTwoToneIcon from "@mui/icons-material/RouteTwoTone";
import CandlestickChartTwoToneIcon from "@mui/icons-material/CandlestickChartTwoTone";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Handle, Position } from "reactflow";
import { Box, Tooltip, Typography } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { htmlToText } from "html-to-text";
import Styles from "./customNode.module.css";
import DesignMenu from "../Menu/pages/DesignMenu";

const CustomNode = memo(
  ({
    data,
    toggleMenu,
    onSelectedId,
    removeNode,
    handleAddNode,
    btnPosition,
    isMenuOpen,
    isMenuOpenBtn,
    toggleMenuBtn,
    selectedId,
  }) => {
    const handleEditorChange = (html) => {
      const text = htmlToText(html, {
        wordwrap: 130,
      });
      return text;
    };

    const sendId = (id) => {
      toggleMenu();
      onSelectedId(id);
    };

    const getIcon = (nodeType) => {
      switch (nodeType) {
        case "Send message":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e4fafb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ChatTwoToneIcon style={{ color: "#34b8ca", fontSize: "30px" }} />
            </Box>
          );
        case "Buttons":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#fff6d9",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RadioButtonCheckedTwoToneIcon
                style={{ color: "#f5be4f", fontSize: "30px" }}
              />
            </Box>
          );
        case "Forms":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormatListBulletedTwoToneIcon
                style={{ color: "#359cec", fontSize: "30px" }}
              />
            </Box>
          );
        case "Calendar":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CalendarMonthTwoToneIcon
                style={{ color: "#359cec", fontSize: "30px" }}
              />
            </Box>
          );
        case "Image Carousel":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e4fafb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ViewCarouselTwoToneIcon
                style={{ color: "#2ab9cd", fontSize: "30px" }}
              />
            </Box>
          );
        case "Collect Input":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CandlestickChartTwoToneIcon
                style={{ color: "#359cec", fontSize: "30px" }}
              />
            </Box>
          );
        case "Carousel":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e4fafb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ViewDayTwoToneIcon
                style={{ color: "#f5be4f", fontSize: "30px" }}
              />
            </Box>
          );
        case "Send an email":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#f8f0fc",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EmailTwoToneIcon
                style={{ color: "#cb62e4", fontSize: "30px" }}
              />
            </Box>
          );
        case "Branch":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#fff6d9",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RouteTwoToneIcon
                style={{ color: "#f5be4f", fontSize: "30px" }}
              />
            </Box>
          );
        case "Slider":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TuneTwoToneIcon style={{ color: "#359cec", fontSize: "30px" }} />
            </Box>
          );
        case "File Upload":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InsertDriveFileTwoToneIcon
                style={{ color: "#359cec", fontSize: "30px" }}
              />
            </Box>
          );
        case "Delay":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <UpdateIcon style={{ color: "#2ab9cd", fontSize: "30px" }} />
            </Box>
          );
        case "Flow":
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#e7f5fe",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AccountTreeIcon style={{ color: "#2ab9cd", fontSize: "30px" }} />
            </Box>
          );
        default:
          return (
            <Box
              style={{
                padding: "10px",
                backgroundColor: "#fff0f5",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AdsClickTwoToneIcon
                style={{ color: " #ed2b2b", fontSize: "30px" }}
              />
            </Box>
          );
      }
    };

    const getBorderColor = (nodeType) => {
      switch (nodeType) {
        case "start":
          return "#ed2b2b";
        case "Send message":
          return "#34b8ca";
        case "Forms":
          return "#359cec";
        case "Calendar":
          return "#359cec";
        case "Image Carousel":
          return "#2ab9cd";
        case "Buttons":
          return "#f5be4f";
        case "Collect Input":
          return "#359cec";
        case "Carousel":
          return "#f5be4f";
        case "Send an email":
          return "#cb62e4";
        case "Branch":
          return "#f5be4f";
        case "Slider":
          return "#359cec";
        case "File Upload":
          return "#359cec";
        case "Delay":
          return "#2ab9cd";
        case "Flow":
          return "#2ab9cd";
        default:
          return "#34b8ca";
      }
    };

    // useEffect(() => {
    //   const handleBackspace = (event) => {
    //     console.log(data.end);
    //     console.log(event);

    //     if (event.key === "Backspace") {
    //       event.preventDefault();
    //     }
    //   };

    //   document.addEventListener("keydown", handleBackspace);

    //   return () => {
    //     document.removeEventListener("keydown", handleBackspace);
    //   };
    // }, [data.end]);

    // const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);
    // const [isMouseOverBox, setIsMouseOverBox] = useState(false);
    // const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(false);
    // const [hideTimeout, setHideTimeout] = useState(null);

    // const handleMouseEnterBox = () => {
    //   clearTimeout(hideTimeout);
    //   setIsMouseOverBox(true);
    //   setIsDeleteIconVisible(true);
    // };

    // const handleMouseLeaveBox = () => {
    //   setIsMouseOverBox(false);
    //   setHideTimeout(setTimeout(() => setIsDeleteIconVisible(false), 1000));
    // };

    // const handleMouseEnterIcon = () => {
    //   clearTimeout(hideTimeout);
    //   setIsMouseOverIcon(true);
    //   setIsDeleteIconVisible(true);
    // };

    // const handleMouseLeaveIcon = () => {
    //   setIsMouseOverIcon(false);
    //   // Check if the mouse is not over the box before setting the timeout
    //   if (!isMouseOverBox) {
    //     setHideTimeout(setTimeout(() => setIsDeleteIconVisible(false), 1000));
    //   }
    // };
    // const handleDeleteNode = () => {
    //   removeNode(data.id); // Pass the id of the node to the removeNode function
    // };

    return (
      <Box
        className={Styles.mainContainer}
        // onMouseEnter={handleMouseEnterBox}
        // onMouseLeave={handleMouseLeaveBox}
        style={{
          borderLeft: `5px solid ${getBorderColor(data.type)}`,
          background: "white",
          width: "260px",
          height: "120px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "3px",
          ...(data.type === "Button" && {
            background: "#858585",
            border: "none",
            borderLeft: "none",
            color: "white",
            width: "100px",
            height: "50px",
          }),
          ...(data.type === "Card" && {
            background: "#858585",
            border: "none",
            borderLeft: "none",
            color: "white",
            width: "100px",
            height: "50px",
          }),
          ...(data.type === "start" && {
            height: "80px",
          }),
        }}
      >
        {data.type !== "start" ? (
          <Handle type="target" position={Position.Top} />
        ) : (
          ""
        )}
        {/* {data.end === true ? (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: "hidden", zIndex: "1000" }}
        />
      ) : (
        <Handle style={{zIndex:"1000"}} type="source" position={Position.Bottom} />
      )} */}
        <Handle
          style={{ zIndex: "1000" }}
          type="source"
          position={Position.Bottom}
        />

        {/*{isDeleteIconVisible &&
        data.type !== "Button" &&
        data.type !== "Card" &&
        data.type !== "start" && (
          <Box
            onMouseEnter={handleMouseEnterIcon}
            onMouseLeave={handleMouseLeaveIcon}
            style={{
              position: "absolute",
              top: "0px",
              right: "-39px",
              padding: "7px 0px",
              cursor: "pointer",
              backgroundColor: "white",
              width: "32px",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
              border: "1px solid #d3d3d3",
            }}
          >
            <Tooltip title="Delete" placement="right">
              <DeleteOutlineIcon
                className={Styles.deleteIcon}
                onClick={handleDeleteNode}
              />
            </Tooltip>
          </Box>
        )}*/}

        {data.type !== "Button" && data.type !== "Card" ? (
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                margin: "0 0 0 20px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getIcon(data.type)}
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                  title={data.label}
                >
                  {data.label}
                </Typography>
              </Box>

              {data.end === true && (
                <Fragment>
                  <AddCircleOutlineOutlinedIcon
                    onClick={() => sendId(data.id)}
                    style={{
                      position: "absolute",
                      bottom:
                        data.type === "start"
                          ? "-32px"
                          : data.type === "Delay" ||
                            data.type === "Flow" ||
                            data.type === "Send an email" ||
                            data.type === "Image Carousel"
                          ? "-52px"
                          : handleEditorChange(data.description).trim() !== ""
                          ? "-68px"
                          : "-59px",
                      left: "86px",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                  />

                  {isMenuOpen && data.id === selectedId && (
                    <DesignMenu
                      type="normal"
                      btnPosition={btnPosition}
                      addNode={handleAddNode}
                    ></DesignMenu>
                  )}
                </Fragment>
              )}
            </Box>
            {handleEditorChange(data.description) && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "215px",
                  marginLeft: "20px",
                  marginTop: "15px",
                }}
              >
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "215px",
                    textAlign: "center",
                    color: "#858585",
                    fontSize: "12px",
                  }}
                  title={handleEditorChange(data.description)}
                >
                  {handleEditorChange(data.description)}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Fragment>
            {data.end === true && (
              <Fragment>
                <AddCircleOutlineOutlinedIcon
                  onClick={() => sendId(data.id)}
                  style={{
                    position: "absolute",
                    bottom: "-17px",
                    left: "28px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
                {isMenuOpen && data.id === selectedId && (
                  <DesignMenu
                    type="btn"
                    btnPosition={btnPosition}
                    addNode={handleAddNode}
                  ></DesignMenu>
                )}
              </Fragment>
            )}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                marginLeft: "20px",
              }}
            >
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "55px",
                  textAlign: "center",
                  color: "white",
                  fontSize: "12px",
                }}
                title={data.label}
              >
                {data.label}
              </Typography>
            </Box>
          </Fragment>
        )}
      </Box>
    );
  }
);

export default CustomNode;
