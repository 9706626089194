import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Niyat_logo from "../../../../../assets/images/logo.jpg";

import BeatLoader from "react-spinners/BeatLoader";

import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  Button,
  OutlinedInput,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import axios from "axios";
import Styles from "../styles/newpassword.module.css";
import { baseURL } from "../../../../../axiosApi/axiosApi";

const NewPassword = () => {
  const [viewerLoading, setViewerLoading] = useState(false);

  const [password, setpassword] = useState();
  const params = useParams();
  const userID = params.userID;
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value?.trim();
  };

  const navigatehandler = async (e) => {
    const email = localStorage.getItem("EmailForNewPass");
    const args = {
      UserName: email,
      Newpassword: password,
    };
    setViewerLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/chatbot/Forgetpassword?forgetpass=${JSON.stringify(args)}`
      );
      if (response.status === 200) {
        navigate("/login");
        setViewerLoading(false);
        localStorage.removeItem("EmailForNewPass");
      }
    } catch (e) {
      console.log("ERROR");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfrimPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePassChange = async (e, type) => {
    const newPass = e.target.value;
    setpassword(newPass);

    setValue(type, newPass);
    await trigger(type);
  };

  const handleConfirmPassChange = async (e, type) => {
    const newConfirmPass = e.target.value;
    setConfirmPassword(newConfirmPass);

    setValue(type, newConfirmPass);
    await trigger(type);
  };

  const passwordValidation = (value) => {
    const confirmPasswordValue = value || "";
    const enteredPassword = password || "";

    return confirmPasswordValue === enteredPassword || "Passwords do not match";
  };

  const watchPassword = watch("password");

  useEffect(() => {
    if (watchPassword) {
      trigger("confirmPassword");
    }
  }, [watchPassword, trigger]);

  const isPasswordValid = (value) => {
    // Add your custom password validation logic here
    const containsCharacters = /[a-zA-Z]/.test(value);
    const containsSpecialCases = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const containsNumbers = /\d/.test(value);
    const containsUpperCaseLetter = /[A-Z]/.test(value);

    return (
      containsCharacters &&
      containsSpecialCases &&
      containsNumbers &&
      containsUpperCaseLetter
    );
  };

  return (
    <Fragment>
      <Box className={Styles.mainNewPassCont}>
        <Box className={Styles.formCont}>
          <img src={Niyat_logo} alt="niyat-logo"></img>
          <Box className={Styles.words}>
            <Typography className={Styles.title}>New Password</Typography>
          </Box>
          <form onSubmit={handleSubmit(navigatehandler)}>
            <FormControl isInvalid={errors.password}>
              <Typography>Password</Typography>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  minLength: {
                    value: 8,
                    message: "Minimum length is 8 characters",
                  },
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "It Mustn't contain only spaces",
                    passwordRequirements: (value) =>
                      isPasswordValid(value) ||
                      "Password must contain characters, special cases, numbers, and an uppercase letter",
                  },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    onChange={(e) => {
                      handlePassChange(e, "password");
                    }}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.password && errors.password.message}
              </Typography>
            </FormControl>

            <FormControl isInvalid={errors.confirmPassword}>
              <Typography>Confirm Password</Typography>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "This Field Is Required",
                  validate: {
                    noOnlySpaces: (value) =>
                      !isOnlySpaces(value) || "It Mustn't contains only spaces",
                    passwordMatch: passwordValidation,
                  },
                }}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    onChange={(e) => {
                      handleConfirmPassChange(e, "confirmPassword");
                    }}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfrimPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <Typography className={Styles.errorValidation}>
                {errors.confirmPassword && errors.confirmPassword.message}
              </Typography>
            </FormControl>

            <Button className={Styles.reset} variant="contained" type="submit">
              {viewerLoading ? (
                <BeatLoader color="black" loading={viewerLoading} size={7} />
              ) : (
                <Fragment>Reset</Fragment>
              )}
            </Button>
          </form>
        </Box>
      </Box>
    </Fragment>
  );
};

export default NewPassword;
