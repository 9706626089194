import React from "react";
import Modal from "react-modal";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { Button } from "@mui/material";
import Styles from "./imageModal.module.css";

const ImageModal = ({ modalIsOpen, closeModal, selectedImage }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Image Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 9001,
        },
        content: {
          width: "40%",
          height: "70%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 20px 20px 20px",
        },
      }}
    >
      <Button onClick={closeModal} className={Styles.closeModalBtn}>
        <CloseTwoToneIcon className={Styles.closeIcon}></CloseTwoToneIcon>
      </Button>
      <img src={selectedImage} alt="" className={Styles.modalImage} />
    </Modal>
  );
};

export default ImageModal;
