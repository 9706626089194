import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DesignButton from "../../../../../components/ui/button/DesignButton";
import "../../styles/slider.css";

const ExcludeTimes = ({
  allData,
  daysOfWeek,
  excludedDaysAndTimes,
  setExcludedDaysAndTimes,
}) => {
  console.log(JSON.stringify(excludedDaysAndTimes));
  const [defaultFrom, setDefaultFrom] = useState(
    allData.data[allData.index].defaultFrom
  );
  const [defaultTo, setDefaultTo] = useState(
    allData.data[allData.index].defaultTo
  );

  const [selectedDay, setSelectedDay] = useState(
    allData.data[allData.index].selectedSpecificTimeToDate
  );

  const [timeText, setTimeText] = useState(
    allData.data[allData.index].timeText
  );

  useEffect(() => {
    setTimeText(allData.data[allData.index].timeText);
    setDefaultFrom(allData.data[allData.index].defaultFrom);
    setDefaultTo(allData.data[allData.index].defaultTo);
    setSelectedDay(allData.data[allData.index].selectedSpecificTimeToDate);
  }, [allData.data, allData.index]);

  const [isTimeTextValid, setIsTimeTextValid] = useState(false);

  useEffect(() => {
    const isValid = timeText.trim() !== "" && /[a-zA-Z]/.test(timeText);

    setIsTimeTextValid(isValid);
  }, [timeText]);

  const [emptyTimeText, setEmptyTimeText] = useState(false);

  const handleTimeTextChange = (event) => {
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setEmptyTimeText(true);
    } else {
      setEmptyTimeText(false);
    }
    setTimeText(newValue);
    allData.data[allData.index].timeText = newValue;
    allData.reRender();
  };

  useEffect(() => {
    if (emptyTimeText) {
      const newEmptyTextErrors = {
        id: allData.data[allData.index].data.id,
        title:
          allData.data[allData.index].data.label === ""
            ? "Untitled"
            : `${allData.data[allData.index].data.label}`,
        subject: "There is no text added in this action block (exclude time)",
      };

      allData.settingErrors((prevErrors) => {
        const updatedErrors = prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                "There is no text added in this action block (exclude time)"
            )
        );

        return [...updatedErrors, newEmptyTextErrors];
      });
    } else {
      // Remove the error if emptyTimeText is false
      allData.settingErrors((prevErrors) =>
        prevErrors.filter(
          (error) =>
            !(
              error.id === allData.data[allData.index].data.id &&
              error.subject ===
                "There is no text added in this action block (exclude time)"
            )
        )
      );
    }
  }, [emptyTimeText, allData.data[allData.index].data.label]);

  const [showTable, setShowTable] = useState(
    allData.data[allData.index].scheduleOpining
  );

  const openTable = () => {
    const lastValue = showTable;
    setShowTable(!lastValue);
    allData.data[allData.index].scheduleOpining = !lastValue;
  };

  const [duration, setDuration] = useState(
    allData.data[allData.index].duration
  );

  useEffect(() => {
    setTimeText(allData.data[allData.index].timeText);
    setDuration(allData.data[allData.index].duration);
    setShowTable(allData.data[allData.index].scheduleOpining);
  }, [allData.data, allData.index]);

  const handleDurationChange = (e) => {
    const value = e.target.value;
    setDuration(value);
    allData.data[allData.index].duration = value;
  };

  const handleDefaultTimeChange = (key, value) => {
    setExcludedDaysAndTimes((prev) => {
      const updatedDaysAndTimes = {};
      Object.keys(prev).forEach((day) => {
        updatedDaysAndTimes[day] = {
          ...prev[day],
          [key]: value,
        };
      });
      return updatedDaysAndTimes;
    });

    // Update allData.data[allData.index].timing
    const updatedTiming = { ...allData.data[allData.index].timing };

    Object.keys(updatedTiming).forEach((day) => {
      updatedTiming[day] = {
        ...updatedTiming[day],
        [key]: value,
      };
    });

    // Set the updated timing object
    allData.data[allData.index].timing = updatedTiming;
    allData.reRender();
  };

  const handleDefaultFromchange = (e) => {
    const value = e.target.value;
    setDefaultFrom(value);
    allData.data[allData.index].defaultFrom = value;
    allData.reRender();
    handleDefaultTimeChange("from", value);
  };

  const handleDefaultTochange = (e) => {
    const value = e.target.value;
    setDefaultTo(value);
    allData.data[allData.index].defaultTo = value;
    allData.reRender();
    handleDefaultTimeChange("to", value);
  };

  const handleTimeChange = (day, key, value) => {
    setExcludedDaysAndTimes((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [key]: value,
      },
    }));
    // Update allData.data[allData.index].timing
    const updatedTiming = { ...allData.data[allData.index].timing };
    updatedTiming[day] = {
      ...updatedTiming[day],
      [key]: value,
    };

    // Set the updated timing object
    allData.data[allData.index].timing = updatedTiming;
    allData.reRender();
  };

  const handleResetDayToDefault = () => {
    setExcludedDaysAndTimes((prev) => ({
      ...prev,
      [selectedDay]: {
        from: defaultFrom,
        to: defaultTo,
      },
    }));

    setSelectedDay("");

    allData.data[allData.index].selectedSpecificTimeToDate = "";
    allData.reRender();

    allData.data[allData.index].timing[selectedDay] = {
      from: defaultFrom,
      to: defaultTo,
    };
    allData.reRender();
  };


  return (
    <Box className="exclude-time slider-comp">
      <Box className="email-subject">
        <Typography>Show message</Typography>
        <TextField
          id="subject"
          multiline
          rows={3}
          inputProps={{ maxLength: 75 }}
          value={timeText}
          onChange={handleTimeTextChange}
        ></TextField>
        <Stack flexDirection="row" justifyContent="space-around">
          <Typography
            variant="body2"
            color={isTimeTextValid ? "#333333" : "error"}
            className="time-error-msg"
          >
            {isTimeTextValid ? "Valid" : "Not Valid"}
          </Typography>
          <Typography variant="body2" className="remaining-text">
            {`${75 - timeText.length}`} Remaining
          </Typography>
        </Stack>
      </Box>
      <Box className="duration-cont">
        <Typography> Duration </Typography>
        <TextField
          type="number"
          value={duration}
          onChange={handleDurationChange}
          inputProps={{ min: 0 }}
        ></TextField>
      </Box>
      <Box className="default-time-container">
        <Box>
          <Typography>Default Timing</Typography>
        </Box>
        <Box className="selected-specific-time">
          <Box className="from-box">
            <Typography htmlFor="defaultFrom">Default From:</Typography>
            <TextField
              type="time"
              id="defaultFrom"
              value={defaultFrom}
              onChange={(e) => handleDefaultFromchange(e)}
              className="from-text"
            />
          </Box>
          <Box className="to-box">
            <Typography htmlFor="defaultTo">Default To:</Typography>
            <TextField
              type="time"
              id="defaultTo"
              value={defaultTo}
              onChange={(e) => handleDefaultTochange(e)}
              className="to-text"
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <FormControl variant="outlined" className="specific-time-container">
          <Typography>Set Specific Time To Date</Typography>
          <Select
            displayEmpty
            value={selectedDay}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue !== "") {
                setSelectedDay(selectedValue);
                allData.data[
                  allData.index
                ].selectedSpecificTimeToDate = selectedValue;
              }
            }}
            fullWidth
          >
            {Object.entries(excludedDaysAndTimes).map(([day, value]) => (
              <MenuItem key={day} value={day} className="menu-select">
                {daysOfWeek[day]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedDay && (
          <Box className="set-specific-time-to-date">
            <Box className="time-cont">
              <Box className="spicfic-from-box">
                <Typography>From:</Typography>
                <TextField
                  type="time"
                  id="defaultSecondFrom"
                  value={excludedDaysAndTimes[selectedDay]?.from || defaultFrom}
                  onChange={(e) =>
                    handleTimeChange(selectedDay, "from", e.target.value)
                  }
                  className="from-text"
                />
              </Box>
              <Box className="spicfic-to-box">
                <Typography>To:</Typography>
                <TextField
                  type="time"
                  id="defaultSecondTo"
                  value={excludedDaysAndTimes[selectedDay]?.to || defaultTo}
                  onChange={(e) =>
                    handleTimeChange(selectedDay, "to", e.target.value)
                  }
                  className="to-text"
                />
              </Box>
            </Box>

            <Box className="restore-spicific-time">
              <Button
                variant="contained"
                onClick={handleResetDayToDefault}
                color="error"
                className="restore-btn-time"
                sx={{ minWidth: "80px" }}
              >
                Restore Default
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box className="button-container">
        <Box className="btn-container">
          <DesignButton
            func={openTable}
            text="Schedule Table"
            isTable={true}
          ></DesignButton>
        </Box>
        {showTable && (
          <table>
            <thead>
              <tr className="table-head">
                <th>Day</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(excludedDaysAndTimes).map(
                ([day, { from, to, meetingDuration }], index) => (
                  <tr key={day} className="table-body">
                    <td>{daysOfWeek[day]}</td>
                    <td>{from}</td>
                    <td>{to}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </Box>
    </Box>
  );
};

export default ExcludeTimes;
