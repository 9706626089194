import React from "react";

import { Box, Tooltip, Typography } from "@mui/material";

import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

const CarousalCardDetailsHeader = ({ allData, handleBackToMainPage }) => {
  return (
    <Box className="card-details-header-cont" onClick={handleBackToMainPage}>
      <Box className="Back-to-list-cont">
        <Box className="left-arrow-icon-cont">
          <KeyboardBackspaceTwoToneIcon />
        </Box>
        <Typography className="back-top-form">Back to List</Typography>
      </Box>
      <Tooltip title="Close" placement="top">
        <Box onClick={allData.onClose} className="close-icon-cont">
          <CloseTwoToneIcon />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CarousalCardDetailsHeader;
