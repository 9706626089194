import React, { useState, useRef, useEffect } from "react";

import BeatLoader from "react-spinners/BeatLoader";

import SliderHeader from "../../../components/ui/sliderHeader/SliderHeader";
import DeleteButton from "../../../components/ui/button/DeleteButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Stack, TextField, Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { baseURL } from "../../../axiosApi/axiosApi";

import "./styles/slider.css";

const DraggableImages = ({
  image,
  index,
  handleInputChange,
  handleUrlInputChange,
  handleDeleteImage,
  textInputErrors,
  urlInputErrors,
}) => {
  return (
    <Draggable draggableId={`quillItem-${index}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            opacity: snapshot.isDragging ? 0.5 : 1,
            ...provided.draggableProps.style,
            marginBottom: "20px",
          }}
        >
          <ul key={index} className="img-informations">
            <li style={{ listStyle: "none" }}>
              <Box className="image-container">
                {image.isLoading ? (
                  <BeatLoader
                    className="spinner"
                    color="#36d7b7"
                    loading={image.isLoading}
                    size={10}
                  />
                ) : (
                  <>
                    <img src={image.path} width={100} height={100} alt="" />
                    <Typography className="img-text">
                      {image.fileName}
                    </Typography>
                  </>
                )}
              </Box>
              <Stack
                alignItems="center"
                justifyContent="center"
                className="input-container"
              >
                <TextField
                  id="standard-basic"
                  label="Image Caption"
                  type="text"
                  variant="outlined"
                  value={image.text}
                  onChange={(e) => handleInputChange(e, index)}
                  error={textInputErrors[index] !== ""}
                  helperText={textInputErrors[index]}
                />

                <TextField
                  id="standard-basic"
                  className="standard-basic-url"
                  label="URL"
                  type="url"
                  variant="outlined"
                  value={image.URL}
                  onChange={(e) => handleUrlInputChange(e, index)}
                  error={urlInputErrors[index] !== ""}
                  helperText={urlInputErrors[index]}
                />
                <DeleteButton
                  func={() => handleDeleteImage(index)}
                  text="Remove"
                  dis={image.isLoading}
                ></DeleteButton>
              </Stack>
            </li>
          </ul>
        </Box>
      )}
    </Draggable>
  );
};

const ImageCaruselModal = ({ allData }) => {
  const [allImages, setAllImages] = useState(
    allData.data[allData.index].imageCarousel
  );

  // useEffect(() => {
  //   if (allImages.length === 0) {
  //     const newEmptyTextErrors = {
  //       id: allData.data[allData.index].data.id,
  //       title:
  //         allData.data[allData.index].data.label === ""
  //           ? "Untitled"
  //           : `${allData.data[allData.index].data.label}`,
  //       subject: "You need at least to upload one file",
  //     };

  //     allData.settingErrors((prevErrors) => {
  //       const updatedErrors = prevErrors.filter(
  //         (error) =>
  //           !(
  //             error.id === allData.data[allData.index].data.id &&
  //             error.subject === "You need at least to upload one file"
  //           )
  //       );

  //       return [...updatedErrors, newEmptyTextErrors];
  //     });
  //   } else {
  //     // Remove the error if allImages is not empty
  //     allData.settingErrors((prevErrors) =>
  //       prevErrors.filter(
  //         (error) =>
  //           !(
  //             error.id === allData.data[allData.index].data.id &&
  //             error.subject === "You need at least to upload one file"
  //           )
  //       )
  //     );
  //   }
  // }, [allImages, allData.data[allData.index].data.label]);

  const [textInputs, setTextInputs] = useState([]);
  const [urlInputs, setUrlInputs] = useState([]);
  const [urlInputErrors, setUrlInputErrors] = useState([]);
  const [isURLValidate, setIsURLValid] = useState([]);
  const [isTextValidate, setIsTextValidate] = useState([]);
  const [textInputErrors, setTextInputErrors] = useState([]);

  useEffect(() => {
    // Filter out empty strings from urlInputErrors

    const updatedUrlInputErrors = allData.data[
      allData.index
    ].imageCarousel.map((carouselItem) =>
      carouselItem.URL.trim() !== ""
        ? isURLValid(carouselItem.URL)
          ? ""
          : "Invalid URL"
        : ""
    );

    const nonEmptyUrlInputErrors = updatedUrlInputErrors.filter(
      (error) => error !== ""
    );

    const newEmptyTextErrors = nonEmptyUrlInputErrors.map((index) => ({
      id: allData.data[allData.index].data.id,
      title:
        allData.data[allData.index].data.label === ""
          ? "Untitled"
          : `${allData.data[allData.index].data.label}`,
      subject: `Invalid URL`,
    }));

    allData.settingErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter(
        (error) =>
          !(
            error.id === allData.data[allData.index].data.id &&
            error.subject === `Invalid URL`
          )
      );

      return [...updatedErrors, ...newEmptyTextErrors];
    });
  }, [urlInputErrors, allData.data[allData.index].data.label]);

  const fileInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [iterator, setIterator] = useState(
    allData.data[allData.index].imageCounter
  );

  useEffect(() => {
    setAllImages(allData.data[allData.index].imageCarousel);
    setIterator(allData.data[allData.index].imageCounter);
  }, [allData.index, allData.data]);

  const handleUplaodImage = (e) => {
    // taking the image data and store it in the file
    const file = e.target.files[0];

    // handle the formData to path it to the fetch
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", file.name);

    setIsLoading(true);

    const updatedImages = [...allImages];
    const fullImageData = {
      path: "",
      text: `Image ${iterator}`,
      URL: "",
      files: file,
      fileName: file.name,
      isLoading: true,
    };

    setAllImages((prevImages) => [...prevImages, fullImageData]);

    fetch(`${baseURL}/file`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text(); // Parse the response as plain text
      })
      .then((data) => {
        const parts = data.split("wwwroot\\");
        const extractedString = parts[1];
        const fullPath = `${baseURL}/` + extractedString;

        fullImageData.path = fullPath;
        fullImageData.isLoading = false;

        setAllImages((prevImages) => {
          const updatedImages = [...prevImages];
          const index = updatedImages.findIndex(
            (image) => image === fullImageData
          );
          if (index !== -1) {
            updatedImages[index] = fullImageData;
          }
          return updatedImages;
        });

        allData.data[allData.index].imageCarousel.push(fullImageData);
        allData.reRender();

        // Add an empty text input for the new image
        setTextInputs([...textInputs, `Image ${iterator}`]);

        // Add an empty url input for the new image
        setUrlInputs([...urlInputs, ""]);

        // Add initial empty validation errors for the new image
        setTextInputErrors([...textInputErrors, ""]);
        setUrlInputErrors([...urlInputErrors, ""]);

        setIterator(iterator + 1);
        setIsLoading(false);

        fileInputRef.current.value = "";
      })
      .catch((error) => {
        console.log("error", error);
        fullImageData.isLoading = false;
        setAllImages(updatedImages);
      });
  };

  const isURLValid = (url) => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  };

  const handleUrlInputChange = (e, index) => {
    const newURLInput = e.target.value;
    const updatedURLInputs = [...urlInputs];
    updatedURLInputs[index] = newURLInput;
    setUrlInputs(updatedURLInputs);

    // Only validate the URL and set the error message if it's non-empty
    let isValid = true;
    if (newURLInput.trim() !== "") {
      isValid = isURLValid(newURLInput);
    }
    const updatedUrlInputErrors = [...urlInputErrors];
    updatedUrlInputErrors[index] = isValid ? "" : "Invalid URL";
    setUrlInputErrors(updatedUrlInputErrors);

    allData.data[allData.index].imageCarousel[index].URL = newURLInput;

    // Update URL validation status for the specific URL input, excluding empty URLs
    const updatedIsURLValid = [...isURLValidate];
    updatedIsURLValid[index] = isValid;
    setIsURLValid(updatedIsURLValid);
  };

  const handleInputChange = (e, index) => {
    const newInput = e.target.value;
    const updatedTextInputs = [...textInputs];
    updatedTextInputs[index] = newInput;
    setTextInputs(updatedTextInputs);

    let isValid = true;
    if (newInput.trim() === "") {
      isValid = false;
    }
    const updatedTextInputErrors = [...textInputErrors];
    updatedTextInputErrors[index] = isValid ? "" : "This Field Can't Be Empty";
    setTextInputErrors(updatedTextInputErrors);

    allData.data[allData.index].imageCarousel[index].text = newInput;

    const updatedIsTextValid = [...isTextValidate];
    updatedIsTextValid[index] = isValid;
    setIsTextValidate(updatedIsTextValid);
  };

  const handleDeleteImage = (index) => {
    const updatedImageCarousel = [...allData.data[allData.index].imageCarousel];
    updatedImageCarousel.splice(index, 1); // Remove the image at the specified index
    setTextInputs([
      ...textInputs.slice(0, index),
      ...textInputs.slice(index + 1),
    ]);
    setUrlInputs([...urlInputs.slice(0, index), ...urlInputs.slice(index + 1)]);
    setTextInputErrors([
      ...textInputErrors.slice(0, index),
      ...textInputErrors.slice(index + 1),
    ]);
    setUrlInputErrors([
      ...urlInputErrors.slice(0, index),
      ...urlInputErrors.slice(index + 1),
    ]);
    setAllImages(updatedImageCarousel);
    allData.data[allData.index].imageCarousel = updatedImageCarousel;
    allData.reRender();
  };

  const moveItem = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedEditorContent = [...allImages];
    const [movedItem] = updatedEditorContent.splice(result.source.index, 1);
    updatedEditorContent.splice(result.destination.index, 0, movedItem);

    setAllImages(updatedEditorContent);
    allData.data[allData.index].imageCarousel = updatedEditorContent;
    allData.reRender();
  };

  return (
    <Box
      className={"slider"}
      style={{ right: allData.isOpen ? "0" : "-350px" }}
    >
      <SliderHeader
        closeData={allData}
        headerMessage={
          "Displays one or more images in a carousel to make it easier for users to browse through"
        }
        toastHeader="Image Carusel"
        nodeType={allData.nodeType}
        isURLValid={isURLValidate}
        isTextArrValid={isTextValidate}
      ></SliderHeader>

      <Box className="slider-compBtn">
        <Box className="message-container">
          <Typography className="message-description">
            Upload the images to be shown here
          </Typography>
        </Box>

        <DragDropContext onDragEnd={moveItem}>
          <Droppable droppableId="quillItems" type="QUILL_ITEM">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {allImages?.map((image, index2) => (
                  <DraggableImages
                    key={index2}
                    index={index2}
                    image={image}
                    handleInputChange={handleInputChange}
                    handleUrlInputChange={handleUrlInputChange}
                    handleDeleteImage={handleDeleteImage}
                    textInputErrors={textInputErrors}
                    urlInputErrors={urlInputErrors}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Box className="image-btn-cont">
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          className={`upload-img ${isLoading ? "disabledBtn" : ""}`}
          disabled={isLoading === true}
        >
          Upload an Image
          <VisuallyHiddenInput
            type="file"
            onChange={handleUplaodImage}
            ref={fileInputRef}
            // accept="image/*,.png,.jpg,.jpeg,.gif,.bmp,.tiff"
            accept="image/jpeg, image/png, image/gif ,image/jpg , image/bmp , image/tiff , image/ico , image/avif , image/apng , image/svg"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ImageCaruselModal;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
